import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { initialState, reducer } from '../reducers/medicalsReducer';
import useMedicalFormValidator from './useMedicalFormValidator';
import { api } from '../utils';

const medicalConditions = [
  'Asthma',
  'Attention-Deficit/Hyperactivity Disorder',
  'Allergies',
  'Diabetes',
  'Hearing Impairment',
  'Visual Impairment',
  'Sickle Cell Disease',
];

const uploadFile = (file, fileInfo, fileSetter) => {
  const formData = new FormData();
  formData.append('playhouse_file', file);
  let fileUpdates = { file, inProgress: true };
  fileSetter({
    ...fileInfo,
    ...fileUpdates,
  });
  // axios.post('http://localhost:4000/controller/uploader.php', formData, {
  axios.post(api.fileUploaderUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => {
      fileUpdates = {
        ...fileUpdates,
        uploadData: response.data,
      };

      fileSetter({
        ...fileInfo,
        ...fileUpdates,
      });
    })
    .catch(() => {
      // console.log(e);
      fileUpdates = { ...fileUpdates, error: true };
      fileSetter({
        ...fileInfo,
        ...fileUpdates,
      });
    })
    .finally(() => {
      fileUpdates = { ...fileUpdates, inProgress: false };
      fileSetter({
        ...fileInfo,
        ...fileUpdates,
      });
    });
};

const useMedicalRegistration = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { validateMedicalForm, requiredFields } = useMedicalFormValidator({ state, dispatch });
  const [selectedFile, setSelectedFile] = useState();
  const [isAllRequiredFilesUploaded, setIsAllRequiredFilesUploaded] = useState(false);
  const [PhysicalExaminationReportFile, setPhysicalExaminationReportFile] = useState({});
  const [immunizationFile, setImmunizationFile] = useState({});
  const [formIsCompleted, setFormIsCompleted] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const url = api.playhouseRegistrationUrl;

  const isSetMedicalReports = () => {
    if (
      !PhysicalExaminationReportFile?.uploadData?.file
      || !immunizationFile?.uploadData?.file
    ) {
      setIsAllRequiredFilesUploaded(false);
      return false;
    }
    setIsAllRequiredFilesUploaded(true);
    return true;
  };

  useEffect(() => {
    isSetMedicalReports();
  }, [
    PhysicalExaminationReportFile?.uploadData?.file,
    immunizationFile?.uploadData?.file,
  ]);

  useEffect(() => {
    setFormIsCompleted(state?.errors.length < 1);
  }, [state?.errors?.length]);

  const handleChange = (key, val) => {
    const payload = {};
    payload[key] = val;
    dispatch({ type: 'update', payload });
    let updatedErrors = [];
    const { errors } = state;
    if (requiredFields.includes(key) && (!val || val.trim() === '')) {
      updatedErrors = errors.includes(key) ? errors : [...errors, key];
    } else {
      updatedErrors = errors.filter((err) => err !== key);
    }
    dispatch({
      type: 'update',
      payload: {
        errors: updatedErrors,
      },
    });
  };

  const handleFileChange = (event, fileType) => {
    setSelectedFile(event.target.files[0]);
    let fileFunc;
    let fileInfo;
    switch (fileType) {
      case 'PhysicalExaminationReport':
        fileInfo = PhysicalExaminationReportFile;
        fileFunc = setPhysicalExaminationReportFile;
        break;
      case 'Immunization':
        fileInfo = immunizationFile;
        fileFunc = setImmunizationFile;
        break;
      // case 'PPD':
      //   fileInfo = PPDFile;
      //   fileFunc = setPPDFile;
      //   break;
      default:
        break;
    }
    uploadFile(event.target.files[0], fileInfo, fileFunc, isSetMedicalReports);
  };

  const removeFile = (fileType) => {
    let fileFunc;
    switch (fileType) {
      case 'PhysicalExaminationReport':
        fileFunc = setPhysicalExaminationReportFile;
        break;
      case 'Immunization':
        fileFunc = setImmunizationFile;
        break;
      default:
        break;
    }

    fileFunc({});
  };

  const handleMedicalConditionChange = (selected) => {
    let updatedMedicalConditions = [];
    if (state?.medicalConditions) {
      updatedMedicalConditions = state?.medicalConditions
        .find((cond) => cond === selected)
        ? state?.medicalConditions.filter((cond) => cond !== selected)
        : [...state?.medicalConditions, selected];
    } else {
      updatedMedicalConditions = [selected];
    }
    dispatch({
      type: 'update',
      payload: {
        medicalConditions: updatedMedicalConditions,
      },
    });
  };

  const handleAsthmaSignsChange = (selected) => {
    let updatedAsthmaSigns = [];
    if (state?.asthmaSigns) {
      updatedAsthmaSigns = state?.asthmaSigns
        .find((cond) => cond === selected)
        ? state?.asthmaSigns.filter((cond) => cond !== selected)
        : [...state?.asthmaSigns, selected];
    } else {
      updatedAsthmaSigns = [selected];
    }
    dispatch({
      type: 'update',
      payload: {
        asthmaSigns: updatedAsthmaSigns,
      },
    });
  };

  const handleAllergySignsChange = (selected) => {
    let updatedAllergySigns = [];
    if (state?.allergySigns) {
      updatedAllergySigns = state?.allergySigns
        .find((cond) => cond === selected)
        ? state?.allergySigns.filter((cond) => cond !== selected)
        : [...state?.allergySigns, selected];
    } else {
      updatedAllergySigns = [selected];
    }
    dispatch({
      type: 'update',
      payload: {
        allergySigns: updatedAllergySigns,
      },
    });
  };

  const handleFormSubmission = () => {
    if (
      validateMedicalForm()
      && isSetMedicalReports()
    ) {
      const data = {
        ...state,
        medicalForm: true,
        physicalExaminationReport: PhysicalExaminationReportFile?.uploadData?.file,
        immunizationReport: immunizationFile?.uploadData?.file,
      };
      axios.post(url, data)
        // axios.post('http://localhost:4000/controller/register.php', data)
        .then(() => {
          setIsSuccess(true);
          dispatch({ type: 'reset' });
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return {
    handleChange,
    state,
    medicalConditions,
    handleMedicalConditionChange,
    handleAsthmaSignsChange,
    handleAllergySignsChange,
    uploadFile,
    handleFileChange,
    handleFormSubmission,
    selectedFile,
    PhysicalExaminationReportFile,
    immunizationFile,
    removeFile,
    formIsCompleted,
    isSubmitting,
    isSuccess,
    isAllRequiredFilesUploaded,
  };
};

export default useMedicalRegistration;
