import React from 'react';
import PropTypes from 'prop-types';
import { Person } from '@material-ui/icons';

const JIconButton = ({
  onClick,
  label,
  Icon,
  color,
}) => (
  <button
    className="border text-xs p-2"
    type="button"
    onClick={onClick}
    style={{
      background: color === 'danger' ? 'red' : '#fff',
      color: color ? '#fff' : '#000',
    }}
  >
    <Icon style={{ fontSize: '16px', marginRight: '5px' }} />
    {label}
  </button>
);

JIconButton.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func,
};

JIconButton.defaultProps = {
  Icon: Person,
  color: null,
};

export default JIconButton;
