/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PlayOptions = ({
  image,
  name,
  text,
  bgColor,
  textColor = '#fff',
  link,
}) => (
  <div className="rounded-md md:flex p-6 px-8 mb-5 items-center" style={{ backgroundColor: bgColor }}>
    <div className="flex-none">
      <img className="rounded-full h-60 w-60 object-cover" src={image} alt={name} />
    </div>
    <div style={{ color: textColor }}>
      <div
        dangerouslySetInnerHTML={{ __html: `<h1 class="text-lg mb-4">${name}</h1><div class="text-sm">${text}</div>` }}
        className="text-left flex-grow md:ml-8"
      />
      {
        link
        && (
          <div className={`btn-${textColor.toLowerCase().replace('#', '')}`}>
            {
              link.toLowerCase().includes('http:')
                ? <a href={link}>Book now</a>
                : <Link to={link}>Book now</Link>
            }
          </div>
        )
      }
    </div>
  </div>
);

PlayOptions.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  link: PropTypes.string,
};

PlayOptions.defaultProps = {
  image: null,
  name: null,
  text: null,
  bgColor: '',
  textColor: '#FFF',
  link: '',
};

export default PlayOptions;
