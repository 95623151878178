import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Main = ({ title, children }) => (
  <div>
    <Helmet>
      <title>{title ? `${title}` : 'Jamboree'}</title>
    </Helmet>
    <Header />
    {children}
    <Footer />
  </div>
);

Main.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Main;
