import React from 'react';
import ImageGallery from 'reactjs-image-gallery';
import { useParams } from 'react-router-dom';
import MainLayout from '../layout/Main';
import { data, colors } from '../utils';

const { albumList } = data;
const { orange } = colors;

const AlbumDetail = () => {
  const { id } = useParams();
  const [album, setAlbum] = React.useState();
  const [albumName, setAlbumName] = React.useState('');

  React.useState(() => {
    // Albums
    if (Array.isArray(albumList)) {
      const found = albumList.find((alb) => alb.id === id);
      if (found) {
        const { photos, name } = found;
        setAlbumName(name);
        const albumFmt = photos.map((ph) => ({
          url: ph.imageUrl,
          thumbUrl: ph.thumbnailUrl,
          title: ph.name,
        }));
        setAlbum(albumFmt);
      }
    }
  }, []);

  return (
    <MainLayout title={`Gallery - ${albumName}`}>
      <div className="text-center pt-4 pb-20 max-w-6xl mx-auto" style={{ minHeight: '70vh' }}>
        <h1 className="text-2xl mb-4" style={{ color: orange }}>
          {albumName}
          {' '}
          (Album)
        </h1>
        {album && (
        <ImageGallery
          images={album}
          imageBackgroundColor="orange"
        />
        )}
      </div>
    </MainLayout>
  );
};

export default AlbumDetail;
