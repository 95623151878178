import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-awesome-reveal';

const ExperienceLayout = ({ title, color, children }) => (
  <div className="md:flex">
    <div className="md:flex-shrink md:pt-6">
      <Slide direction="left" triggerOnce>
        <h1 className="text-2xl mb-8 md:mb-0 md:text-5xl transform md:rotate-90" style={{ color }}>
          <span className="block md:inline-block transform md:rotate-180">{title}</span>
        </h1>
      </Slide>
    </div>
    <div className="md:ml-10 md:flex-grow">
      <Slide direction="up" triggerOnce>
        {children}
      </Slide>
    </div>
  </div>
);

ExperienceLayout.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  children: PropTypes.element.isRequired,
};

ExperienceLayout.defaultProps = {
  color: '#fff',
};

export default ExperienceLayout;
