import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import ContactForm from '../components/ContactForm';
import MainLayout from '../layout/Main';
import { colors } from '../utils';

const { blue, orange } = colors;

const Contact = () => (
  <MainLayout title="Contact us">
    <Fade triggerOnce />
    <div className="contact-bg-left" />
    <Fade triggerOnce />
    <div className="contact-bg-right" />
    <div className="pt-10 pb-20 px-4">
      <Slide triggerOnce direction="up">
        <h1 className="text-center text-3xl mb-10" style={{ color: blue }}>Leave us a message</h1>
      </Slide>

      <ContactForm />

      <Fade direction="up" triggerOnce>
        <div className="mx-auto max-w-sm bg-white">
          <h1 className="text-2xl mb-4" style={{ color: orange }}>Contact information</h1>
          <p className="mb-2 text-sm">Telephone: +233(0) 557 760 093</p>
          <p className="mb-2 text-sm">Telephone: +233(0) 30 279 2131</p>
          <p className="mb-2 text-sm">
            Email:
            {' '}
            <a href="mailto:info@myjamboreegh.com" style={{ color: blue }}>info@myjamboreegh.com</a>
          </p>
          <p className="mb-2 text-sm">Address: No. 8 Wuogon Road, East Cantonments, Accra, Ghana</p>
        </div>
      </Fade>
    </div>
  </MainLayout>
);

export default Contact;
