import React from 'react';
import { colors, data } from '../utils';
// import ImageTextCard from '../components/ImageTextCard';
import ExperienceLayout from '../layout/Experience';
import PlayOptions from '../components/PlayOptions';

const { learn: learnOptions } = data;
const { orange } = colors;

const Learn = () => (
  <ExperienceLayout title="Learn" color={orange}>
    <div className="text-center">
      {
                    learnOptions.map((opt, idx) => (
                      <PlayOptions
                        imageLeft={(idx + 1) % 2}
                        text={opt.text}
                        name={opt.name}
                        image={opt.image}
                        color={opt.color}
                        textColor={opt.textColor}
                        bgColor={opt.bgColor}
                        link={opt.link}
                      />
                    ))
                }
    </div>
  </ExperienceLayout>
);

export default Learn;
