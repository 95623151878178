/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import Popup from './Popup';
import { colors } from '../utils';

const { blue, purple } = colors;

const NewsletterSubscription = ({ showNewsletter, setShowNewsletter }) => {
  const [fullname, setFullname] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState();
  const [processing, setProcessing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setErrorMsg(false);
    const baseUrl = 'https://www.myjamboreegh.com';
    // const baseUrl = 'http://localhost/jamboree';
    const url = `${baseUrl}/controller/process.php?action=subscribe&fullname=${fullname}&email=${email}&phone=${phone}`;
    try {
      const { data: { message } } = await axios.get(url);
      setFullname('');
      setEmail('');
      setPhone('');
      setOpen(true);
      setSuccessMessage(message);
      setShowNewsletter(false);
    } catch (err) {
      const errMsg = err?.response?.data?.message || 'An error occurred. Please try again.';
      setErrorMsg(errMsg);
    }
    setProcessing(false);
  };

  return (
    <>
      {
                showNewsletter
                  ? (
                    <div
                      style={{
                        background: 'rgba(0,0,0,0.5)',
                        position: 'fixed',
                        width: '100%',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        height: '100vh',
                        top: '0',
                        left: 0,
                        zIndex: '9999999999',
                      }}
                    >
                      <div style={{ height: '100vh' }} className="">
                        <Fade>
                          <div className="relative w-full p-10 flex text-center items-center justify-center" style={{ height: '100vh' }}>
                            <div
                              className="bg-white w-full max-w-2xl p-3 newsletter-modal"
                              style={{
                                backgroundImage: 'url(assets/images/characters/subscribe-char.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '180px',
                              }}
                            >
                              <div className="p-4 md:p-10 relative" style={{ border: '1px solid #ddd' }}>
                                <div className="absolute" style={{ top: 0, right: 0 }}>
                                  <div className="relative">
                                    <button type="button" className="close-icon" onClick={() => setShowNewsletter(false)} />
                                  </div>
                                </div>
                                <div className="flex flex-col max-w-sm md:max-w-xs mx-auto">
                                  <h2 className="text-2xl mb-2" style={{ color: blue }}>Never miss an update</h2>
                                  <p className="mb-8" style={{ color: purple }}>Join our mailing list</p>
                                  {
                                    errorMsg && <div className="mb-4 text-xs text-red-500">{errorMsg}</div>
                                  }
                                  <form className="contact-form w-full mx-auto" onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                      <input className="w-full" type="text" required value={fullname} onChange={(e) => setFullname(e.target.value)} name="fullName" placeholder="Full name" />
                                    </div>
                                    <div className="mb-4">
                                      <input className="w-full" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                      <input className="w-full" type="text" required value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="Phone number" />
                                    </div>
                                    <div>
                                      <button type="submit" className="jamb-btn" disabled={processing}>
                                        {processing ? 'Processing' : 'Submit'}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Fade>
                      </div>
                    </div>
                  )
                  : ''
            }
      <Popup open={open} setOpen={setOpen} hideBooking>
        <h3>{successMessage}</h3>
      </Popup>
    </>
  );
};

NewsletterSubscription.propTypes = {
  showNewsletter: PropTypes.bool.isRequired,
  setShowNewsletter: PropTypes.func.isRequired,
};

export default NewsletterSubscription;
