/* eslint-disable react/no-danger */
import React from 'react';
import { colors, data } from '../utils';
import ExperienceLayout from '../layout/Experience';

const { cafe: cafeData } = data;

const { red } = colors;

const Cafe = () => (
  <ExperienceLayout title="Café" color={red}>
    <div className="text-center">
      <div className="mb-10">
        <img src={cafeData?.image} alt="Cafe banner" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: cafeData?.text }} />
    </div>
  </ExperienceLayout>
);

export default Cafe;
