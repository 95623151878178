import React from 'react';
import PropTypes from 'prop-types';
import { Slide, Fade } from 'react-awesome-reveal';
import MainLayout from '../layout/Main';
import { colors, data } from '../utils';
import Popup from '../components/Popup';

const { orange, green, purple } = colors;
const { parties: { partySteps } } = data;

const Parties = () => {
  const [openPopup, setOpenPopup] = React.useState(false);

  return (
    <MainLayout title="Parties">
      <div className="pb-20">
        <div className="banner-about text-center mb-10">
          <Fade triggerOnce><img className="mx-auto" src="assets/images/banners/banner-parties.jpg" alt="parties" /></Fade>
        </div>

        <div className="max-w-5xl mx-auto text-center px-4">
          <Slide triggerOnce direction="up">
            <h1 className="text-3xl mb-2" style={{ color: orange }}>Easy steps to book your party/play date</h1>
          </Slide>

          <div className="sm:flex sm:flex-wrap">
            {
            partySteps.map(
              ({ name, text, image }, idx) => (
                <StepCard
                  stepNumber={idx + 1}
                  image={image}
                  name={name}
                  text={text}
                />
              ),
            )
          }
          </div>

          <Fade triggerOnce>
            <div className="flex justify-center space-x-4">
              <button
                type="button"
                className="jamb-button"
                target="_blank"
                rel="noreferrer"
                onClick={() => setOpenPopup(true)}
              >
                View Party Packages
              </button>
              <a
                className="jamb-button-outlined"
                target="_blank"
                rel="noreferrer"
                href="https://www.myjamboreegh.com/booking"
              >
                Start booking your party!!!
              </a>
            </div>
          </Fade>
        </div>
        <Popup open={openPopup} setOpen={setOpenPopup} url="https://www.myjamboreegh.com/booking?package=event">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.myjamboreegh.com/docs/party_packages.pdf"
          >
            <div>
              <img src="assets/images/packages/party-1.jpg" alt="Jamboree Party package" />
            </div>
            <div>
              <img src="assets/images/packages/party-2.jpg" alt="Jamboree Party package" />
            </div>
          </a>
        </Popup>
      </div>
    </MainLayout>
  );
};

const StepCard = ({ image, stepNumber, text }) => (
  <div className="md:w-1/3 text-center px-10 mb-10 md:mb-20">
    <Slide direction="up" triggerOnce delay={+stepNumber}>
      <img src={image} className="h-40 w-40 object-fit bg-blue mx-auto mb-4" alt="party-step" />
      <h1 style={{ color: green }} className="text-4xl mb-4">
        {stepNumber}
        .
      </h1>
      <p style={{ color: purple }} className="text-2xl">{text}</p>
    </Slide>
  </div>
);

StepCard.propTypes = {
  image: PropTypes.string,
  stepNumber: PropTypes.number,
  text: PropTypes.string,
};

StepCard.defaultProps = {
  image: null,
  stepNumber: null,
  text: null,
};

export default Parties;
