export default {
  blue: 'rgb(23, 191, 253)',
  purple: 'rgb(198, 103, 183)',
  green: 'rgb(133, 227, 39)',
  red: 'rgb(246, 20, 20)',
  orange: 'rgb(246, 148, 42)',
  amber: 'rgb(200, 200, 58)',
  yellow: 'rgb(254, 243, 128)',
  sunYellow: 'rgb(253 212 82)',
  teal: 'rgba(20, 246, 205, 1)',
};
