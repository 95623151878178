import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JTextField from './JTextField';
import JRadio from './JRadio';
import JCheckbox from './JCheckbox';
import JSelect from './JSelect';
import nationalities from '../utils/data/nationalities.json';
import religions from '../utils/data/religions.json';
// import { data } from '../utils';

// const { playhouse: { registration: { ethnicgroups } } } = data;
const ChildInformation = ({
  id,
  dispatch,
  number,
  activities,
  firstName,
  middleName,
  surname,
  age,
  gender,
  homeAddress,
  dateOfBirth,
  nationality,
  // ethnicOrigin,
  religion,
  previousSchoolAttended,
  disabilities,
  errors,
  levelActivities,
  otherReligion,
  lunchIncluded,
}) => {
  const [formErrors, setErrors] = useState([]);
  const [showActivities, setShowActivities] = useState(false);
  const [otherReligionField, setShowOtherReligionField] = useState(false);

  const handleChange = (type, value) => {
    const field = Object.keys(value)[0];
    let updatedErrors = [];

    if (!value[field] || value[field] === '') {
      if (errors) {
        updatedErrors = errors.includes(field) ? errors : [...errors, field];
      } else {
        updatedErrors = [field];
      }
    } else {
      updatedErrors = errors && errors.filter((err) => err !== field);
    }
    dispatch({ type, payload: { id, ...value, errors: updatedErrors } });
  };

  const handleActivityChange = (value) => {
    dispatch({
      type: 'updateChild',
      payload: {
        id,
        activities: activities.includes(value)
          ? activities.filter((act) => act !== value) : [...activities, value],
      },
    });
  };

  useEffect(() => {
    if (Array.isArray(errors)) {
      setErrors(errors);
    }
  }, [errors]);

  useEffect(() => {
    setShowOtherReligionField(religion?.toLowerCase() === 'other');
  }, [religion]);
  return (
    <div>
      <h2 className="text-gray-400 mb-4">
        Child #
        {number}
      </h2>
      <div className="md:flex text-left md:gap-x-2">
        <JTextField
          label="First name"
          value={firstName}
          onChange={
            (e) => handleChange('updateChild', { firstName: e.target.value })
          }
          hasError={formErrors.includes('firstName')}
          errorMessage="First name is required"
          required
        />
        <JTextField
          label="Middle name"
          value={middleName}
          required={false}
          onChange={
            (e) => handleChange('updateChild', { middleName: e.target.value })
          }
        />
        <JTextField
          label="Surname"
          value={surname}
          required
          hasError={formErrors.includes('surname')}
          errorMessage="Surname is required"
          onChange={
            (e) => handleChange('updateChild', { surname: e.target.value })
          }
        />
        <div>
          <JRadio
            label="Gender"
            options={[
              {
                label: 'Male',
                value: 'male',
              },
              {
                label: 'Female',
                value: 'female',
              },
            ]}
            selected={gender}
            hasError={formErrors.includes('gender')}
            errorMessage="Gender is required"
            onChange={
              (val) => handleChange('updateChild', { gender: val })
            }
            required
          />
        </div>
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Home Address"
          value={homeAddress}
          onChange={
            (e) => handleChange('updateChild', { homeAddress: e.target.value })
          }
          hasError={formErrors.includes('homeAddress')}
          errorMessage="Home address is required"
          required
        />
        <JTextField
          label="Date of birth"
          type="date"
          value={dateOfBirth}
          onChange={
            (e) => handleChange('updateChild', { dateOfBirth: e.target.value })
          }
          hasError={formErrors.includes('dateOfBirth')}
          errorMessage="Date of birth is required"
          required
        />
        <JTextField
          label="Age"
          value={age}
          onChange={
            (e) => handleChange('updateChild', { age: e.target.value })
          }
          hasError={formErrors.includes('age')}
          errorMessage="Age is required"
          required
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JSelect
          label="Nationality"
          value={nationality}
          onChange={
            (e) => handleChange('updateChild', { nationality: e.target.value })
          }
          hasError={formErrors.includes('nationality')}
          errorMessage="Nationality is required"
          required
          options={nationalities.map((name) => ({ label: name, value: name }))}
        />
        {/* <JSelect
          label="Ethnic Origin"
          value={ethnicOrigin}
          onChange={
            (e) => handleChange('updateChild', { ethnicOrigin: e.target.value })
          }
          hasError={formErrors.includes('ethnicOrigin')}
          errorMessage="Ethnic origin is required"
          required
          options={ethnicgroups.map((name) => ({ label: name, value: name }))}
        /> */}
        <div className="md:w-1/2">
          <JSelect
            label="Religion"
            value={religion}
            onChange={
              (e) => handleChange('updateChild', { religion: e.target.value })
            }
            hasError={formErrors.includes('religion')}
            errorMessage="Religion is required"
            required
            options={religions.map((name) => ({ label: name, value: name }))}
          />
          {
            otherReligionField
            && (
              <JTextField
                label="Please specify religion"
                value={otherReligion}
                onChange={
                  (e) => handleChange('updateChild', { otherReligion: e.target.value })
                }
              />
            )
          }
        </div>
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Previous School Attended"
          value={previousSchoolAttended}
          onChange={
            (e) => handleChange('updateChild', { previousSchoolAttended: e.target.value })
          }
          hasError={formErrors.includes('previousSchoolAttended')}
          errorMessage="Previous school attended is required"
          required
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Details of any special educational needs/disabilities"
          rows={4}
          value={disabilities}
          onChange={
            (e) => handleChange('updateChild', { disabilities: e.target.value })
          }
          required={false}
        />
      </div>

      <div>
        <JCheckbox
          selected={showActivities}
          onChange={() => setShowActivities(!showActivities)}
        >
          After school activities (optional)
        </JCheckbox>
      </div>

      <div className={`pl-4 border-l p-4 ${showActivities ? 'block' : 'hidden'}`}>
        {
          levelActivities && levelActivities.map((activity) => (
            <>
              {
                activity.children ? (
                  activity.children.map((subAct) => (
                    <JCheckbox
                      key={subAct.name}
                      selected={activities.includes(subAct.name)}
                      onChange={() => handleActivityChange(subAct.name)}
                    >
                      {`${activity?.name} - ${subAct?.name}`}
                    </JCheckbox>
                  ))
                ) : (
                  <JCheckbox
                    key={activity.name}
                    selected={activities.includes(activity.name)}
                    onChange={() => handleActivityChange(activity.name)}
                  >
                    {activity?.name}
                  </JCheckbox>
                )
              }
            </>
          ))
        }
      </div>

      <div className="mt-6 md:flex">
        <div className="mr-4">
          Lunch (optional)
        </div>
        <div>
          <JRadio
            label="Lunch"
            isInline
            options={['Yes', 'No'].map((val) => ({ label: val, value: val }))}
            onChange={(val) => handleChange('updateChild', { lunchIncluded: val })}
            selected={lunchIncluded}
            hasError={formErrors.includes('lunchIncluded')}
            errorMessage="Lunch option is reequired"

          />
        </div>
      </div>

    </div>
  );
};

ChildInformation.propTypes = {
  number: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  surname: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  homeAddress: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  // ethnicOrigin: PropTypes.string.isRequired,
  religion: PropTypes.string.isRequired,
  previousSchoolAttended: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabilities: PropTypes.string,
  otherReligion: PropTypes.string,
  lunchIncluded: PropTypes.string,
  id: PropTypes.number.isRequired,
  levelActivities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ChildInformation.defaultProps = {
  disabilities: '',
  middleName: '',
  otherReligion: '',
  lunchIncluded: '',
};

export default ChildInformation;
