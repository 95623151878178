import colors from '../colors';

const {
  blue, yellow,
} = colors;

const learnOptions = [
  {
    name: 'Jamboree PlayHouse',
    image: 'assets/images/learn.jpg',
    text: `
        <p class="mb-2">Jamboree PlayHouse provides an excellent early learning experience in a warm and nurturing environment. We understand that choosing the right Day Care to suit your child’s needs is an important decision, and we strive to meet the expectations of every family we serve.</p>
        <p class="mb-2">PlayHouse Day Care has three programs designed to cater for the educational
        and developmental needs of children at different stages of their early learning
        years.</p>
        <p class="mb-2">Our beautiful and modern facility provides each child with the opportunity to play, learn and grow!
        Find out more about our full week (5 days) or flexi week (3 day) options
        Call us on 0557760093 or visit our facility at No. 8 Wuogon Road, East Cantonments, Accra.
        </p>
        `,
    bgColor: blue,
    link: '/playhouse',
  },
  {
    name: 'Jamboree Play Group',
    image: 'assets/images/grow-thumb.jpg',
    text: `
            <p class="mb-2">Jamboree PlayGroup is a fun and interactive play group session for children from 1 to 3 years old. Parents and guardians are welcome to join our toddlers every Wednesday from 10am – 12pm as they enjoy circle time, sing along, creative play, music and movement, sensory play and more. 
            </p>
        `,
    textColor: '#000',
    bgColor: yellow,
    link: 'https://www.myjamboreegh.com/booking?package=activities',
  },
  // {
  //   name: 'StoryBox',
  //   image: 'assets/images/experience/learn/learn-4.jpg',
  //   text: `
  //           <p class="mb-2">StoryBox comes to Jamboree every Thursday!
  //           Enjoy interactive stories, songs and circle time using puppets,
  // props, pictures and Makaton
  //           sign. Sessions also include drama, games, activities, singing
  // with instruments,
  // parachute play
  //           and more!</p>
  //       `,
  //   textColor: '#000',
  //   bgColor: yellow,
  //   link: 'https://www.myjamboreegh.com/booking',
  // },
  // {
  //   name: 'Discovery Club',
  //   image: 'assets/images/experience/learn/discovery.jpg',
  //   text: `
  //           <p class="mb-2">Sign up for a class in adventure once a month! At
  //           Discovery Club, nothing is off limits. Together with a
  //           qualified instructor, your children will discover,
  //           explore and create! From science projects, cooking
  //           classes, gardening, art and crafts, music, dance,
  //           culture and field trips! Come and Discover the
  //           Amazing you at Jamboree!</p>
  //       `,
  //   bgColor: orange,
  //   link: 'https://www.myjamboreegh.com/booking?package=activities',
  // },
];

export default learnOptions;
