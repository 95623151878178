const aboutSummary = `
<p>Discover the Amazing You!</p></br>

<p>Jamboree is a fun, exciting and modern place for Discovery!</p></br>

<p>Play, learn, create and discover the AMAZING YOU at our all-inclusive Activity Centre
and Playground which has been purposefully designed to enhance learning and play
experiences for children and families.</p>
`;

const bannerText = `<p>Jamboree is a fun, exciting and modern place for Discovery!</p>
<p>Play, learn, create and discover the AMAZING YOU at our all-inclusive Day Care, Activity Centre and Playground which has been purposefully designed to enhance learning and play experiences for children and families.</p>
<p>At Jamboree, our primary focus is to help children harness their learning and developmental potential during their fundamental growth years. Our children are encouraged to cultivate their curiosity and discover their strengths and interests through our STEAM (Science, Technology, Engineering, Arts and Math) based educational programs, structured activities and imaginative play.</p>  
`;

const aboutInfo = [
  {
    text: `We offer a fantastic range of age appropriate activities and our 
    facility and programs are professionally approved to nurture healthy minds and bodies. With programs curated for toddlers to activities for pre-teens, Jamboree welcomes children from as young as 6 months to 12 years.`,
  },
  {
    text: `We have lots of fun activities and vacation programs, indoor, outdoor and toddler playgrounds, 
    music, art and dance studios, robotics and coding facilities, library, swimming pool, party spaces, café, 
    salon and more!`,
  },
  {
    text: `At Jamboree, safety is a priority! Our staff are professionally trained in first aid and child 
    safety procedures and we strictly enforce Covid -19 and other health and safety protocols. 
    In addition, the facility is under 24-hour camera and professional security surveillance. `,
  },
  {
    text: `Jamboree’s world class facility is conveniently located in the safe and residential area of 
    Cantonments and is open from 9am - 5pm from Monday to Saturday and on Sunday for private parties and events.`,
  },
  {
    text: 'Discover the AMAZING YOU at Jamboree – Play, Learn, Grow…',
  },
];

export {
  aboutSummary,
  aboutInfo,
  bannerText,
};
