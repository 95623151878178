const requiredFields = [
  'firstName',
  'lastName',
  'bloodGroup',
  'height',
  'weight',
  'hasHealthInsurance',
  'isAccidentInsured',
  'parentName',
];

const asthmaRequiredFields = [
  'asthmaSigns',
  'asthmaSeverity',
  'attackHelp',
  'asthmaMedication',
  'asthmaDose',
];

const allergyRequiredFields = [
  'allergySigns',
  'allergySeverity',
  'allergyTreatment',
  'allergyMedicationNames',
  'allergyDose',
];

const useMedicalFormValidator = ({ state, dispatch }) => {
  const validateMedicalForm = () => {
    const errors = [];
    requiredFields.forEach((field) => {
      if (
        !state[field]
        || state[field].trim() === ''
      ) errors.push(field);
    });

    if (state?.medicalConditions?.includes('Asthma')) {
      asthmaRequiredFields.forEach((field) => {
        if (
          !state[field]
          || (Array.isArray(state[field]) && state[field].length < 1)
          || (!Array.isArray(state[field]) && state[field]?.trim() === '')
        ) errors.push(field);
      });
    }

    if (state?.medicalConditions?.includes('Allergies')) {
      allergyRequiredFields.forEach((field) => {
        if (
          !state[field]
          || (Array.isArray(state[field]) && state[field].length < 1)
          || (!Array.isArray(state[field]) && state[field]?.trim() === '')
        ) errors.push(field);
      });
    }

    dispatch({
      type: 'update',
      payload: {
        errors,
      },
    });

    return errors.length < 1;
  };

  return {
    validateMedicalForm,
    requiredFields,
  };
};

export default useMedicalFormValidator;
