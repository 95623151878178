import React from 'react';
import PropTypes from 'prop-types';

const Triangle = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 211 529" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M211 0H0V529L211 0Z" fill={fill} />
  </svg>
);

Triangle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

Triangle.defaultProps = {
  fill: '#10DBB6',
  width: '211',
  height: '529',
};

export default Triangle;
