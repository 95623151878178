import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import ConsentCard from '../components/ConsentCard';
import JCheckbox from '../components/JCheckbox';
import JTextField from '../components/JTextField';
import useMedicalRegistration from '../hooks/useMedicalRegistration';
import FileUploader from '../components/FileUploader';
// import { useParams } from 'react-router-dom';
import { data, colors } from '../utils';
import JRadio from '../components/JRadio';
import AlertModal from '../components/AlertModal';

const { red } = colors;

const {
  playhouse: {
    medicalConditions,
    asthmaSigns,
    asthmaSeverity,
    allergicReactions,
    immunizationRequirements,
  },
} = data;

const PlayHouseMedicals = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    handleChange,
    state,
    handleMedicalConditionChange,
    handleFileChange,
    handleAsthmaSignsChange,
    handleAllergySignsChange,
    immunizationFile,
    PhysicalExaminationReportFile,
    removeFile,
    handleFormSubmission,
    formIsCompleted,
    isSubmitting,
    isSuccess,
    isAllRequiredFilesUploaded,
  } = useMedicalRegistration();

  useEffect(() => {
    if ([false, true].includes(isSuccess)) {
      setShowModal(true);
    }
  }, [isSuccess]);

  return (
    <>
      {
        showModal
        && (
          <AlertModal
            success={isSuccess}
            title={isSuccess ? 'Application submitted!' : 'Failed to submit application'}
            message={isSuccess ? 'We\'ll get back to you shortly' : 'Please try again later.'}
            onClick={() => setShowModal(false)}
          />
        )
      }

      <div style={{
        background: 'url(https://www.myjamboreegh.com/images/banners/playgroup-bg.jpg)',
      }}
      >
        <div className="text-center max-w-5xl mx-auto md:pt-10 px-8 pb-20 bg-white">
          <div
            className="text-xl mb-4"
          >
            Medical Information
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <JTextField
              label="First name"
              value={state?.firstName}
              onChange={
                (e) => handleChange('firstName', e.target.value)
              }
              hasError={state?.errors?.includes('firstName')}
              errorMessage="First name is required"
              required
            />
            <JTextField
              label="Middle name"
              value={state?.middleName}
              onChange={
                (e) => handleChange('middleName', e.target.value)
              }
            />
            <JTextField
              label="Last name"
              value={state?.lastName}
              onChange={
                (e) => handleChange('lastName', e.target.value)
              }
              hasError={state?.errors?.includes('lastName')}
              errorMessage="Last name is required"
              required
            />
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <JTextField
              label="Child's blood group"
              value={state?.bloodGroup}
              onChange={
                (e) => handleChange('bloodGroup', e.target.value)
              }
              hasError={state?.errors?.includes('bloodGroup')}
              errorMessage="Blood group is required"
              required
            />
            <JTextField
              label="Height (m)"
              value={state?.height}
              onChange={
                (e) => handleChange('height', e.target.value)
              }
              hasError={state?.errors?.includes('height')}
              errorMessage="Height information is required"
              required
            />
            <JTextField
              label="Weight (kg)"
              value={state?.weight}
              onChange={
                (e) => handleChange('weight', e.target.value)
              }
              hasError={state?.errors?.includes('weight')}
              errorMessage="Weight information is required"
              required
            />
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <div className="w-full">
              <ConsentCard
                text="Is your child enrolled in a health insurance scheme?"
                value={state?.hasHealthInsurance}
                hasError={state?.errors?.includes('hasHealthInsurance')}
                onChange={(val) => handleChange('hasHealthInsurance', val)}
              />
            </div>
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <div className="w-full">
              <ConsentCard
                text="Is your child insured for accident/injury?"
                value={state?.isAccidentInsured}
                hasError={state?.errors?.includes('isAccidentInsured')}
                onChange={(val) => handleChange('isAccidentInsured', val)}
              />
            </div>
          </div>

          {/* INSURANCE */}
          <div className={`${state?.isAccidentInsured === 'Yes' || state?.hasHealthInsurance === 'Yes' ? 'block' : 'hidden'}`}>
            <div className="md:flex text-left md:gap-x-2">
              <JTextField
                label="Insurance Company"
                value={state?.insuranceCompany}
                onChange={
                  (e) => handleChange('insuranceCompany', e.target.value)
                }
                hasError={false}
                errorMessage="Insurance company is required"
                required
              />
              <JTextField
                label="Type of Insurance"
                value={state?.typeOfInsurance}
                onChange={
                  (e) => handleChange('typeOfInsurance', e.target.value)
                }
                hasError={false}
                errorMessage="Type of insurance is required"
                required
              />
              <JTextField
                label="Policy Number"
                value={state?.policyNumber}
                onChange={
                  (e) => handleChange('policyNumber', e.target.value)
                }
                hasError={false}
                errorMessage="Policy number is required"
                required
              />
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <JTextField
                label="Preferred Hospital"
                value={state?.preferredHospital}
                onChange={
                  (e) => handleChange('preferredHospital', e.target.value)
                }
                hasError={false}
                errorMessage="Preferred hospital is required"
                required
              />
              <JTextField
                label="Phone No."
                value={state?.hospitalPhone}
                onChange={
                  (e) => handleChange('hospitalPhone', e.target.value)
                }
                hasError={false}
                errorMessage="Phone number is required"
                required
              />
            </div>
          </div>

          <div className="mt-6 text-left">
            <h2 className="mb-4">Existing Medical Conditions (check all that apply)</h2>
            <div className="md:flex flex-wrap">
              {
                medicalConditions.map((condition) => (
                  <div key={condition} className="md:w-1/2">
                    <JCheckbox
                      onChange={
                        () => handleMedicalConditionChange(condition)
                      }
                      selected={state?.medicalConditions?.includes(condition)}
                    >
                      {condition}
                    </JCheckbox>
                  </div>
                ))
              }
            </div>
          </div>

          {/* ASTHMA */}
          <div
            className={
              `mt-6 text-left 
              ${state?.medicalConditions?.includes('Asthma')
                ? 'block' : 'hidden'}`
            }
          >
            <h2 className="mb-4 text-xl">Asthma</h2>
            <p className="mb-2">
              {`Early warning signs of your child's asthma 
              episode (Check all that apply)`}
            </p>
            <div className="md:flex flex-wrap mb-4">
              {
                asthmaSigns.map((sign) => (
                  <div className="md:w-1/2">
                    <JCheckbox
                      onChange={() => handleAsthmaSignsChange(sign)}
                      key={sign}
                      hasError={state?.errors?.includes('asthmaSigns')}
                      selected={state?.asthmaSigns?.includes(sign)}
                    >
                      {sign}
                    </JCheckbox>
                  </div>
                ))
              }
            </div>

            <p className="mb-2">Severity of your child&apos;s asthma</p>
            <div className="md:flex flex-wrap mb-4">
              <JRadio
                options={asthmaSeverity.map((val) => ({ label: val, value: val }))}
                onChange={(val) => handleChange('asthmaSeverity', val)}
                selected={state?.asthmaSeverity}
                hasError={state?.errors?.includes('asthmaSeverity')}
                isInline
              />
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="What usually helps if an attack occurs?"
                  rows={4}
                  value={state?.attackHelp}
                  onChange={
                    (e) => handleChange('attackHelp', e.target.value)
                  }
                  hasError={state?.errors?.includes('attackHelp')}
                  required
                />
              </div>
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Medication(s) for Asthma"
                  rows={4}
                  value={state?.asthmaMedication}
                  hasError={state?.errors?.includes('asthmaMedication')}
                  onChange={
                    (e) => handleChange('asthmaMedication', e.target.value)
                  }
                  required
                />
              </div>
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Dose"
                  rows={4}
                  value={state?.asthmaDose}
                  hasError={state?.errors?.includes('asthmaDose')}
                  onChange={
                    (e) => handleChange('asthmaDose', e.target.value)
                  }
                  required
                />
              </div>
            </div>
          </div>

          {/* ALLERGIES */}
          <div
            className={
              `mt-6 text-left 
              ${state?.medicalConditions?.includes('Allergies')
                ? 'block' : 'hidden'}`
            }
          >
            <h2 className="mb-4 text-xl">Allergies</h2>
            <p className="mb-2">Allergic to (specific)</p>
            <div className="md:flex text-left md:gap-x-2">
              <JTextField
                label="Food"
                value={state?.allergyFood}
                onChange={
                  (e) => handleChange('allergyFood', e.target.value)
                }
              />
              <JTextField
                label="Medication"
                value={state?.allergyMedication}
                onChange={
                  (e) => handleChange('allergyMedication', e.target.value)
                }
              />
            </div>
            <div className="md:flex text-left md:gap-x-2">
              <JTextField
                label="Insect Bite"
                value={state?.allergyInsectBite}
                onChange={
                  (e) => handleChange('allergyInsectBite', e.target.value)
                }
              />
              <JTextField
                label="Other(s)"
                value={state?.allergyOthers}
                onChange={
                  (e) => handleChange('allergyOthers', e.target.value)
                }
              />
            </div>
            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <p className="mb-2">Severity of your child&apos;s allergic reaction</p>
                <div className="md:flex flex-wrap mb-4">
                  <JRadio
                    options={asthmaSeverity.map((val) => ({ label: val, value: val }))}
                    onChange={(val) => handleChange('allergySeverity', val)}
                    selected={state?.allergySeverity}
                    hasError={state?.errors?.includes('allergySeverity')}
                    isInline
                  />
                </div>
              </div>
            </div>
            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <p className="mb-2">Signs of your child&apos;s allergic reaction</p>
                <div className="md:flex flex-wrap mb-4">
                  {
                    allergicReactions.map((sign) => (
                      <div className="md:w-1/2">
                        <JCheckbox
                          onChange={() => handleAllergySignsChange(sign)}
                          key={sign}
                          selected={state?.allergySigns?.includes(sign)}
                          hasError={state?.errors?.includes('allergySigns')}
                        >
                          {sign}
                        </JCheckbox>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Other signs (describe)"
                  rows={4}
                  value={state?.allergyOtherSigns}
                  onChange={
                    (e) => handleChange('allergyOtherSigns', e.target.value)
                  }
                  required={false}
                />
              </div>
            </div>
            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Treatment for allergies"
                  rows={4}
                  value={state?.allergyTreatment}
                  hasError={state?.errors?.includes('allergyTreatment')}
                  onChange={
                    (e) => handleChange('allergyTreatment', e.target.value)
                  }
                  required
                />
              </div>
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Name of Medication(s)"
                  rows={4}
                  value={state?.allergyMedicationNames}
                  hasError={state?.errors?.includes('allergyMedicationNames')}
                  onChange={
                    (e) => handleChange('allergyMedicationNames', e.target.value)
                  }
                  required
                />
              </div>
            </div>

            <div className="md:flex text-left md:gap-x-2">
              <div className="w-full">
                <JTextField
                  label="Dose"
                  rows={4}
                  value={state?.allergyDose}
                  hasError={state?.errors?.includes('allergyDose')}
                  onChange={
                    (e) => handleChange('allergyDose', e.target.value)
                  }
                  required
                />
              </div>
            </div>
          </div>

          <div id="immunization_record" className="pt-4 text-left mb-8">
            <h3 className="text-xl mb-4">IMMUNIZATION RECORD</h3>
            <p className="mb-4">
              {`(To be completed by a physician, nurse or health
              authority) Please attach records to prove that your child has had, or is in the process of
              completing, all the following immunizations:`}
            </p>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontFamily: '"Kristen ITC", sans-serif' }}>Required immunization</TableCell>
                  <TableCell align="left" style={{ fontFamily: '"Kristen ITC", sans-serif' }}>1</TableCell>
                  <TableCell align="left" style={{ fontFamily: '"Kristen ITC", sans-serif' }}>2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  immunizationRequirements.map(({ id, label }) => {
                    const option1 = `${id}_1`;
                    const option2 = `${id}_2`;
                    return (
                      <TableRow key={id}>
                        <TableCell style={{ fontFamily: '"Kristen ITC", sans-serif' }}>{label}</TableCell>
                        <TableCell align="center" style={{ fontFamily: '"Kristen ITC", sans-serif' }}>
                          <JCheckbox
                            selected={state[option1] === 'Yes'}
                            onChange={() => handleChange(option1, state[option1] === 'Yes' ? 'No' : 'Yes')}
                          />
                        </TableCell>
                        <TableCell align="center" style={{ fontFamily: '"Kristen ITC", sans-serif' }}>
                          <JCheckbox
                            selected={state[option2] === 'Yes'}
                            onChange={() => handleChange(option2, state[option2] === 'Yes' ? 'No' : 'Yes')}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>

            <div className="flex gap-x-4 py-4 items-center mb-2 border-b">
              <FileUploader
                label="Attach a scanned copy of the immunization record"
                onChangeHandler={(e) => handleFileChange(e, 'Immunization')}
                file={immunizationFile}
                removeFile={() => removeFile('Immunization')}
              />
            </div>
          </div>

          <div className="text-left mb-8">
            <h3 className="text-xl mb-2">PHYSICAL EXAMINATION REPORT</h3>
            <p className="mb-3">(To be completed by a physician)</p>
            <a
              href="https://www.myjamboreegh.com/docs/physical_examination_form.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <div className="flex items-center gap-x-2 mb-4" style={{ color: red }}>
                <CloudDownload />
                <p>Download physical examination form</p>
              </div>
            </a>
            <div className="py-4 items-center mb-2 border-b">
              <FileUploader
                label="Attach completed physical examination report"
                onChangeHandler={(e) => handleFileChange(e, 'PhysicalExaminationReport')}
                file={PhysicalExaminationReportFile}
                removeFile={() => removeFile('PhysicalExaminationReport')}
              />
            </div>
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <div className="w-full">
              <JTextField
                label="Any other information"
                rows={4}
                value={state?.otherInformation}
                onChange={
                  (e) => handleChange('otherInformation', e.target.value)
                }
                required={false}
              />
            </div>
          </div>

          <div className="md:flex text-left md:gap-x-2">
            <JTextField
              label="Parent's name"
              value={state?.parentName}
              onChange={
                (e) => handleChange('parentName', e.target.value)
              }
              hasError={state?.errors?.includes('parentName')}
              errorMessage="Parent name is required"
              required
            />
          </div>

          <div className="text-left">
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => {
                if (!isSubmitting) handleFormSubmission();
              }}
            >
              {
                isSubmitting ? 'Submitting...' : 'Submit form'
              }
            </Button>
            {
              !formIsCompleted
              && (
                <p className="text-red-500 text-xs mt-2">
                  Some required fields have not been completed. Please check form again.
                </p>
              )
            }
            {
              !isAllRequiredFilesUploaded
              && (
                <p className="text-red-500 text-xs mt-2">
                  Please make sure all medical reports have been uploaded.
                </p>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayHouseMedicals;
