/* eslint-disable react/no-danger */
import React from 'react';
import { Slide } from 'react-awesome-reveal';
import MainLayout from '../layout/Main';
import { data } from '../utils';

const { about: { aboutInfo, bannerText } } = data;

const About = () => (
  <div style={{
    backgroundImage: 'url(assets/images/banners/bg-about.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  }}
  >
    <MainLayout title="About us">
      <div className="pt-20 pb-20">
        <div className="max-w-5xl mx-auto px-8">
          <Slide direction="up" triggerOnce>
            <div className="md:flex mb-10">
              <div
                className="md:w-1/4 md:flex-0 text-center"
                style={{
                // width: '100%',
                  backgroundImage: "url('assets/images/banners/about-side-1.jpg')",
                  height: 'auto',
                  backgroundPosition: 'center 0px',
                }}
              />
              <div className="md:w-2/4 md:flex-0 text-center p-10" style={{ backgroundColor: '#FEF380' }}>
                <div dangerouslySetInnerHTML={{ __html: bannerText }} />
              </div>
              <div
                className="md:w-1/4 md:flex-0 text-center"
                style={{
                // width: '100%',
                  backgroundImage: "url('assets/images/banners/about-side-2.jpg')",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </Slide>
          <Slide triggerOnce direction="up"><p className="mb-10">{ aboutInfo[0].text }</p></Slide>
          <Slide triggerOnce direction="up"><p className="mb-10">{ aboutInfo[1].text }</p></Slide>
          <Slide triggerOnce direction="up">
            <div className="m-10 md:flex md:items-center">
              <div className="md:w-1/5"><img src="assets/images/banners/serie-golo-1.png" alt="about-img" /></div>
              <div className="md:w-3/5"><img className="mx-auto" src="assets/images/banners/abt-a.png" alt="about-img" /></div>
              <div className="md:w-1/5"><img src="assets/images/banners/serie-golo-2.png" alt="about-img" /></div>
            </div>
          </Slide>
          <Slide triggerOnce direction="up"><p className="mb-10">{ aboutInfo[2].text }</p></Slide>
          <Slide triggerOnce direction="up"><p className="mb-10">{ aboutInfo[3].text }</p></Slide>
          <Slide triggerOnce direction="up"><p className="mb-10">{ aboutInfo[4].text }</p></Slide>
        </div>
      </div>
    </MainLayout>

  </div>
);

export default About;
