import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JTextField from './JTextField';
// import JRadio from './JRadio';

const EmergencyContactInformation = ({
  id,
  dispatch,
  number,
  fullName,
  contactNumber,
  errors,
}) => {
  const [formErrors, setErrors] = useState([]);

  const handleChange = (type, value) => {
    const field = Object.keys(value)[0];
    let updatedErrors = [];

    if (!value[field] || value[field] === '') {
      if (errors) {
        updatedErrors = errors.includes(field) ? errors : [...errors, field];
      } else {
        updatedErrors = [field];
      }
    } else {
      updatedErrors = errors && errors.filter((err) => err !== field);
    }
    dispatch({ type, payload: { id, ...value, errors: updatedErrors } });
  };

  useEffect(() => {
    if (Array.isArray(errors)) {
      setErrors(errors);
    }
  }, [errors]);

  return (
    <div>
      <h2 className="text-gray-400 mb-4">
        Emergency Contact #
        {number}
      </h2>
      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Full name"
          value={fullName}
          onChange={
            (e) => handleChange('updateEmergencyContact', { fullName: e.target.value })
          }
          hasError={formErrors.includes('fullName')}
          errorMessage="Full name is required"
          required
        />
        <JTextField
          label="Contact"
          value={contactNumber}
          required
          hasError={formErrors.includes('contactNumber')}
          errorMessage="Contact number is required"
          onChange={
            (e) => handleChange('updateEmergencyContact', { contactNumber: e.target.value })
          }
        />
      </div>
    </div>
  );
};

EmergencyContactInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmergencyContactInformation;
