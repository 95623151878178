import React from 'react';
import PropTypes from 'prop-types';

const JTextField = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  required,
  hasError,
  errorMessage,
  rows,
}) => {
  const color = hasError ? 'red' : '#17bffd';
  const inputStyle = {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px solid ${color}`,
    padding: '5px 10px',
    borderRadius: '5px',
    // color,
  };
  return (
    <div className="mb-4 flex-1">
      <p className="mb-1 text-xs">
        {label}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </p>
      {
        rows
          ? (
            <textarea
              placeholder={placeholder || label}
              required={required}
              onChange={onChange}
              style={inputStyle}
            >
              {value}
            </textarea>
          )
          : (
            <input
              type={type}
              placeholder={placeholder || label}
              value={value}
              required={required}
              onChange={onChange}
              style={inputStyle}
            />
          )
      }
      <div style={{ color, fontSize: '12px', marginTop: '5px' }}>
        {hasError ? errorMessage : ''}
      </div>
    </div>
  );
};

JTextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
};

JTextField.defaultProps = {
  placeholder: '',
  errorMessage: '',
  type: 'text',
  rows: null,
};

export default JTextField;
