import React from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import { CircularProgress, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const FileUploader = ({
  label,
  removeFile,
  file,
  onChangeHandler,
}) => (
  <>
    {
      file?.inProgress === false && file?.file
        ? (
          <div className="flex gap-x-2 text-xs items-center text-green-600">
            <DescriptionIcon />
            <span
              title={file?.file?.name}
              style={{
                whiteSpace: 'no-wrap',
                minWidth: '80px',
                maxWidth: '250px',
                width: '70%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {file?.file?.name}
            </span>
            <IconButton
              onClick={removeFile}
            >
              <CancelIcon style={{ color: 'red' }} />
            </IconButton>
          </div>
        )
        : (
          <>
            {
              file?.inProgress
                ? (
                  <div className="flex gap-x-2 items-center text-xs">
                    <CircularProgress style={{ width: '20px', height: '20px' }} />
                    <DescriptionIcon />
                    <span>{file?.file?.name}</span>
                  </div>
                )
                : (
                  <div style={{ position: 'relative' }}>
                    <div className="flex gap-x-1 items-center text-xs">
                      <AttachFileIcon />
                      {label || 'Attach file'}
                    </div>
                    <input
                      style={{
                        position: 'absolute',
                        zIndex: 2,
                        background: 'blue',
                        left: 0,
                        top: 0,
                        height: '50px',
                        width: '100%',
                        opacity: 0,
                      }}
                      type="file"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                )
            }
          </>
        )
    }
  </>
);

FileUploader.propTypes = {
  removeFile: PropTypes.func.isRequired,
  file: PropTypes.objectOf(PropTypes.string).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FileUploader.defaultProps = {
  label: null,
};

export default FileUploader;
