import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

export default function NavigationDrawer({ menu, state, toggleDrawer }) {
  return (
    <div>
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer(false)}>
        <div className="p-8" style={{ width: '250px' }}>
          <h2 className="mb-8">Menu</h2>
          {
            menu.map(({ name, to, color }) => (
              <div key={name} className="mb-4" style={{ color }}>
                {name === 'Activities/Booking' ? <a href={to}>{name}</a> : <Link to={to}>{name}</Link>}
              </div>
            ))
          }
        </div>
      </Drawer>
    </div>
  );
}

NavigationDrawer.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  state: PropTypes.objectOf(PropTypes.string),
  toggleDrawer: PropTypes.func.isRequired,
};

NavigationDrawer.defaultProps = {
  state: { left: 'left' },
};
