import helper from '../utils/helper';

const { validateEmail } = helper;

const requiredChildFields = [
  'firstName',
  'surname',
  'age',
  'gender',
  'homeAddress',
  'dateOfBirth',
  'nationality',
  // 'ethnicOrigin',
  'religion',
  'previousSchoolAttended',
  'fullName',
  'contactNumber',
  'idCardType',
  'idNumber',
  'email',
  'relationship',
  'profession',
  'residentialAddress',
  'placeOfWork',
  'workAddress',
  'lunchIncluded',
];

const sectionUpdateType = {
  children: 'updateChild',
  guardians: 'updateGuardian',
  authorizedPersons: 'updateAuthorizedPerson',
  emergencyContacts: 'updateEmergencyContact',
};

const useRegistrationFormValidator = ({ state, dispatch }) => {
  const validateForm = (sectionName) => {
    let errors = 0;

    state[sectionName].forEach((child) => {
      const childErrors = [];
      const keys = Object.keys(child);
      keys.forEach((key) => {
        if (requiredChildFields.includes(key)) {
          let errorFound = false;
          switch (key) {
            case 'age':
              if (
                Number.isNaN(child[key])
                || (parseFloat(child[key], 10) - parseInt(child[key], 10) !== 0)) {
                errorFound = true;
                errors += 1;
              }
              break;
            case 'email':
              if (!validateEmail(child[key])) {
                errorFound = true;
                errors += 1;
              }
              break;
            default:
              if (!child[key] || child[key]?.trim() === '') {
                errors += 1;
                errorFound = true;
              }
          }
          if (errorFound) {
            childErrors.push(key);
          }
        }
      });
      if (childErrors.length > 0) {
        dispatch({
          type: sectionUpdateType[sectionName],
          payload: {
            id: child.id,
            errors: childErrors,
          },
        });
      }
    });
    return errors < 1;
  };

  return {
    validateForm,
  };
};

export default useRegistrationFormValidator;
