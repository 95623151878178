/* eslint-disable react/no-danger */
import React from 'react';
import { colors, data } from '../utils';
import ExperienceLayout from '../layout/Experience';

const { hairhub: hairhubData } = data;

const { purple, blue } = colors;

const Hairhub = () => (
  <ExperienceLayout title="Hairhub Kids" color={purple}>
    <div className="text-center">
      <div className="md:flex">
        <div className="mb-8 md:w-1/3">
          <img src={hairhubData?.image} className="md:w-full max-w-xs md:max-w-lg mx-auto" alt="Cafe banner" />
        </div>
        <div className="md:w-2/3 text-center md:text-left md:pl-8">
          <div style={{ color: blue }} dangerouslySetInnerHTML={{ __html: hairhubData?.text }} />
          <div>
            <a
              className="jamb-button-outlined"
              target="_blank"
              rel="noreferrer"
              href="https://www.fresha.com/a/hair-hub-kids-accra-8-wuogon-road-1btnp1aw/booking?menu=true"
            >
              Book for a session now
            </a>
          </div>
        </div>
      </div>
    </div>
  </ExperienceLayout>
);

export default Hairhub;
