/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import Slider from 'react-elastic-carousel';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Popup from './Popup';

const Carousel = (
  {
    title,
    titleColor,
    items,
    numItems = 4,
    dots = false,
    nav = false,
    spaceItems = false,
    fullWidth = false,
    autoPlay = false,
    hasPopup,
  },
) => {
  const [breakPoints, setBreakPoints] = useState([]);
  React.useEffect(() => {
    if (numItems > 1) {
      setBreakPoints([
        {
          width: 0, itemsToShow: 1,
        },
        { width: 550, itemsToShow: 2 },
        { width: 720, itemsToShow: 4 },
      ]);
    }
  }, [numItems]);

  const [openPopup, setOpenPopup] = useState(false);
  const [popImage, setImage] = useState();
  const [popLink, setLink] = useState();

  return (
    <div className={clsx({ 'max-w-6xl': !fullWidth }, 'mx-auto')}>
      <h1 className={clsx('mb-12 text-3xl')} style={{ color: titleColor }}>{title}</h1>

      <Slider
        itemsToShow={fullWidth ? 1 : (numItems || 1)}
        dots={dots}
        showArrows={nav}
        enableAutoPlay={autoPlay}
        autoPlaySpeed={4000}
        breakPoints={breakPoints}
      >
        {
      items && items.map(({
        name, image, color, type, url,
      }) => {
        if (type === 'video') {
          return (
            <div className="item">
              <video
                style={{ width: '80%', maxWidth: '1000px' }}
                className="mx-auto"
                id="video"
                controls
                autoPlay={false}
                src={url}
              />
            </div>
          );
        }
        return (
          <div className="item">
            <div className={clsx({ 'md:px-8': spaceItems })}>
              <h4
                aria-hidden="true"
                onClick={() => {
                  if (hasPopup) {
                    setImage(image);
                    setLink(url);
                    setOpenPopup(true);
                  }
                }}
                className={clsx({ hidden: !name }, 'mb-4')}
                style={{ color }}
              >
                {name}
              </h4>
              <img
                aria-hidden="true"
                onClick={() => {
                  if (hasPopup) {
                    setImage(image);
                    setLink(url);
                    setOpenPopup(true);
                  }
                }}
                className="w-full"
                src={image}
                alt={name}
              />
            </div>
          </div>
        );
      })
    }
      </Slider>
      <Popup open={openPopup} setOpen={setOpenPopup} url={popLink}>
        <a href={popLink} target="_blank" rel="noreferrer">
          <img src={popImage} alt="Jamboree activity" />
        </a>
      </Popup>
    </div>
  );
};

Carousel.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  numItems: 4,
  dots: false,
  nav: false,
  fullWidth: false,
  autoPlay: false,
  hasPopup: PropTypes.bool,
  spaceItems: PropTypes.bool,
};

Carousel.defaultProps = {
  title: null,
  titleColor: null,
  numItems: 4,
  dots: false,
  nav: false,
  fullWidth: false,
  autoPlay: false,
  hasPopup: false,
  spaceItems: false,
};

export default Carousel;
