import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JTextField from './JTextField';
import JSelect from './JSelect';
import { data } from '../utils';

const { playhouse: { registration: { idCardOptions } } } = data;

const GuardianInformation = ({
  id,
  dispatch,
  number,
  fullName,
  contactNumber,
  email,
  idCardType,
  idNumber,
  relationship,
  profession,
  residentialAddress,
  placeOfWork,
  workAddress,
  errors,
}) => {
  const [formErrors, setErrors] = useState([]);

  const handleChange = (type, value) => {
    const field = Object.keys(value)[0];
    let updatedErrors = [];

    if (!value[field] || value[field] === '') {
      if (errors) {
        updatedErrors = errors.includes(field) ? errors : [...errors, field];
      } else {
        updatedErrors = [field];
      }
    } else {
      updatedErrors = errors && errors.filter((err) => err !== field);
    }
    dispatch({ type, payload: { id, ...value, errors: updatedErrors } });
  };

  useEffect(() => {
    if (Array.isArray(errors)) {
      setErrors(errors);
    }
  }, [errors]);

  return (
    <div>
      <h2 className="text-gray-400 mb-4">
        Parent/Guardian #
        {number}
      </h2>
      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Full name"
          value={fullName}
          onChange={
            (e) => handleChange('updateGuardian', { fullName: e.target.value })
          }
          hasError={formErrors.includes('fullName')}
          errorMessage="Full name is required"
          required
        />
        <JTextField
          label="Relationship to Child"
          value={relationship}
          required
          hasError={formErrors.includes('relationship')}
          errorMessage="Relationship is required"
          onChange={
            (e) => handleChange('updateGuardian', { relationship: e.target.value })
          }
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Phone number"
          value={contactNumber}
          required
          hasError={formErrors.includes('contactNumber')}
          errorMessage="Contact number is required"
          onChange={
            (e) => handleChange('updateGuardian', { contactNumber: e.target.value })
          }
        />

        <JTextField
          label="Email address"
          value={email}
          required
          hasError={formErrors.includes('email')}
          errorMessage="Invalid email address"
          onChange={
            (e) => handleChange('updateGuardian', { email: e.target.value })
          }
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JSelect
          label="ID Card Type"
          value={idCardType}
          options={idCardOptions}
          onChange={
            (e) => handleChange('updateGuardian', { idCardType: e.target.value })
          }
          hasError={formErrors.includes('idCardType')}
          errorMessage="ID card type is required"
          required
        />
        <JTextField
          label="ID Number"
          value={idNumber}
          onChange={
            (e) => handleChange('updateGuardian', { idNumber: e.target.value })
          }
          hasError={formErrors.includes('idNumber')}
          errorMessage="ID number is required"
          required
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Profession"
          value={profession}
          onChange={
            (e) => handleChange('updateGuardian', { profession: e.target.value })
          }
          hasError={formErrors.includes('profession')}
          errorMessage="Profession is required"
          required
        />
        <JTextField
          label="Residential Address"
          value={residentialAddress}
          onChange={
            (e) => handleChange('updateGuardian', { residentialAddress: e.target.value })
          }
          hasError={formErrors.includes('residentialAddress')}
          errorMessage="Residential address is required"
          required
        />
      </div>

      <div className="md:flex text-left md:gap-x-8">
        <JTextField
          label="Place of Work"
          value={placeOfWork}
          onChange={
            (e) => handleChange('updateGuardian', { placeOfWork: e.target.value })
          }
          hasError={formErrors.includes('placeOfWork')}
          errorMessage="Place of work is required"
          required
        />
        <JTextField
          label="Work Address"
          value={workAddress}
          onChange={
            (e) => handleChange('updateGuardian', { workAddress: e.target.value })
          }
          hasError={formErrors.includes('workAddress')}
          errorMessage="Work address is required"
          required
        />
      </div>
    </div>
  );
};

GuardianInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  idCardType: PropTypes.string.isRequired,
  idNumber: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  residentialAddress: PropTypes.string.isRequired,
  placeOfWork: PropTypes.string.isRequired,
  workAddress: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GuardianInformation;
