/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Slide, Fade } from 'react-awesome-reveal';
import { colors, data } from '../utils';
import Popup from '../components/Popup';
import ExperienceLayout from '../layout/Experience';

const { blue, red, green } = colors;
const { grow: growData } = data;
const Grow = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openingHours, setOpeningHours] = useState(false);
  const [activityDescription, setActivityDescription] = useState('');
  const [activityName, setActivityName] = useState('');
  const [ageRange, setAgeRange] = useState(null);
  const openActivity = (obj) => {
    setActivityName(obj.name);
    setActivityDescription(obj.text);
    setOpenDialog(true);
    setOpeningHours(obj.openingHours);
    setAgeRange(obj.ageRange);
  };

  return (
    <ExperienceLayout title="Grow" color={green}>
      <div className="flex flex-wrap justify-center">
        {
          growData.map(({ name, image, ...rest }, idx) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => openActivity({ name, ...rest })}
              className="md:w-1/3 md:px-8 mb-8"
            >
              <Slide direction={idx % 2 ? 'up' : 'down'} triggerOnce>
                <div className="bg-blue-200 h-60 mb-2">
                  <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={image} alt="jamboree" />
                </div>
                <Fade triggerOnce>
                  <div className="flex items-center justify-center">
                    <div className="flex-shrink">
                      <img alt="star" style={{ width: `${idx % 2 ? `${20 + idx}px` : '35px'}` }} src="assets/images/icons/star.png" />
                    </div>
                    <h1 style={{ color: blue }}>{name}</h1>
                  </div>
                </Fade>
              </Slide>
            </div>
          ))
        }
      </div>
      <Popup open={openDialog} setOpen={setOpenDialog}>
        <h1 style={{ color: blue }} className="mb-4 text-xl">
          {activityName}
          {ageRange ? ` (${ageRange})` : ''}
        </h1>
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: activityDescription }} />
        <p className="text-md mb-4" style={{ color: red }}>{openingHours}</p>
      </Popup>
    </ExperienceLayout>
  );
};

const IconText = ({ name, color = blue, onClick }) => (
  <div role="button" tabIndex={0} onKeyDown={() => { }} className="flex items-center mb-4" style={{ cursor: 'pointer' }} onClick={onClick}>
    <div className="flex-shrink pr-2">
      <img src="assets/images/icons/star.png" alt="star" style={{ maxWidth: '48px' }} />
    </div>
    <div style={{ color }} className="text-xl">{name}</div>
  </div>
);

IconText.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconText.defaultProps = {
  name: null,
  color: blue,
  onClick: () => { },
};

export default Grow;
