import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxOutlined, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';

const JCheckbox = ({
  selected,
  hasError,
  onChange,
  errorMessage,
  children,
}) => {
  const color = hasError ? 'red' : '#17bffd';
  return (
    <div className="mb-4 flex">
      {/* eslint-disable-next-line */}
      <div
        style={{
          cursor: 'pointer',
        }}
        className="pr-2"
        onClick={onChange}
      >
        {
          selected ? <CheckBoxOutlined style={{ color }} />
            : <CheckBoxOutlineBlankOutlined style={{ color }} />
        }
      </div>
      <div style={{ color, fontSize: '12px', marginTop: '5px' }}>
        {hasError ? errorMessage : ''}
      </div>
      <div className="flex-1">
        {children}
      </div>
    </div>
  );
};

JCheckbox.propTypes = {
  selected: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

JCheckbox.defaultProps = {
  errorMessage: '',
};

export default JCheckbox;
