import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import JIconButton from './JIconButton';

const RegistrationSectionCard = ({
  dispatch,
  heading,
  actionLabel,
  Component,
  stateData,
  actionIdentity,
  levelActivities,
}) => {
  const mapObj = {
    authorized: 'authorized ',
    emergency: 'emergency ',
  };

  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  const genericIdentifier = (actionLabel.toLowerCase())
    .replace(re, (matched) => mapObj[matched]);

  return (
    <div className="mb-8 md:mb-4">
      <h2 className="text-2xl mb-2">{heading}</h2>
      {
        stateData && stateData.map((data, index) => {
          const number = index + 1;

          return (
            <div className="p-4 md:p-4 border">
              <Component
                dispatch={dispatch}
                number={number}
                // eslint-disable-next-line
                {...data}
                levelActivities={levelActivities}
              />
              {
                stateData.length > 1
                && (
                  <div className="text-right">
                    <JIconButton
                      Icon={Delete}
                      color="danger"
                      label={
                        `Remove 
                      ${data[actionIdentity]
                          ? ` ${data[actionIdentity]}`
                          : ` this ${genericIdentifier}`}
                        `
                      }
                      onClick={() => dispatch({
                        type: `remove${actionLabel}`,
                        id: data.id,
                      })}
                    />
                  </div>
                )
              }
            </div>
          );
        })
      }
      <div className="text-right">
        <JIconButton
          label={`Add another ${genericIdentifier}`}
          onClick={() => dispatch({ type: `add${actionLabel}` })}
        />
      </div>
    </div>
  );
};

RegistrationSectionCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
  stateData: PropTypes.string.isRequired,
  actionIdentity: PropTypes.string.isRequired,
  levelActivities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default RegistrationSectionCard;
