const playhouseDescription = [
  `<p class="mb-8">Jamboree PlayHouse is a children’s Day Care Centre which provides excellent early learning experiences in a warm and nurturing environment. We understand that choosing the right Day Care to suit your child’s needs is an important decision, and we strive to meet the expectations of every family we serve.</p>
  
  <p class="mb-8">PlayHouse Day Care has specialized programs designed to cater for the educational and developmental needs of children at different stages of their early learning years.</p>
  
  <p class="mb-8">Our beautiful and modern facility provides each child with the opportunity to Play, Learn and Grow!</p>
  
  <p class="mb-8">To find out more, call us on 0557760093 or visit our facility at No. 8 Wuogon Road, East Cantonments, Accra.</p>
`];

const programmes = [
  {
    id: 'baby-bees',
    name: 'Baby Bees',
    bgColor: '#FC7D7D',
    textColor: '#fff',
    to: '',
    summary: `Baby Bees Class is dedicated to toddlers aged 1 – 2 years. Using age appropriate, fun and creative programs and activities, we start your little ones off on a journey of lifelong learning. Each class is specifically designed to encourage curiosity and exploration through sensory play, circle time, rhymes, singing, art and more. There is never a dull moment with our Baby Bees class.
    `,
    cover: '/assets/images/playhouse/baby.jpg',
    ages: '1 - 2 years',
    banner: '/assets/images/playhouse/banner-baby-bee.jpg',
    requiredItems: 'http://myjamboreegh.com/docs/playhouse-checklist-baby-bees.pdf',
    levelDetails: [
      {
        type: 'age',
        summary: 'Children should be 1 year old by August 31',
      },
      // {
      //   type: 'enrolment',
      //   summary: 'Maximum Enrolled – 12',
      // },
    ],
    isFull: false,
  },
  {
    id: 'honey-bees',
    name: 'Honey Bees',
    bgColor: '#EEBE24',
    textColor: '#fff',
    summary: `Our Honey Bees experience an amazing time of exploration and discovery. They begin to assert their independence and gain an increasing sense of self.  
    Our fun play-based learning approach engages these young and active minds within a safe and stimulating environment provided by our passionate, qualified and caring team of educators. Together we encourage your child’s learning through creativity, exploration, social interactions, problem solving and physical development. Our Honey Bees are supported to gain confidence and skills across a wide range of developmental milestones as they continue to grow.`,
    to: '',
    cover: '/assets/images/playhouse/honey.jpg',
    ages: '2 - 3 years',
    banner: '/assets/images/playhouse/banner-honey-bee.jpg',
    requiredItems: 'http://myjamboreegh.com/docs/playhouse-checklist-honey-bees.pdf',
    levelDetails: [
      {
        type: 'age',
        summary: 'Children should be 2 years old by August 31',
      },
    ],
  },
  {
    id: 'busy-bees',
    name: 'Busy Bees',
    bgColor: '#c667b7',
    textColor: '#fff',
    summary: `Our Busy Bees program focuses on building cognitive performance through child-initiated cooperative play. Your child will learn effective communication, work independently and in groups and develop a deeper understanding of their world. Our teachers introduce these active and enthusiastic learners to a more structured curriculum and new academic concepts in language, literacy, mathematics, science, technology and art. With a fun and interactive daily schedule and classroom roles we guide them toward following established routines and developing a skill set that will help them gain an appreciable sense of independence.
    `,
    to: '',
    cover: '/assets/images/playhouse/busy.jpg',
    ages: '3 - 4 years',
    banner: '/assets/images/playhouse/banner-busy-bee.jpg',
    requiredItems: 'http://myjamboreegh.com/docs/playhouse-checklist-busy-bees.pdf',
    levelDetails: [
      {
        type: 'age',
        summary: 'Children should be 3 years old by August 31',
      },
    ],
  },
  {
    id: 'bumble-bees',
    name: 'Bumble Bees',
    bgColor: '#17BFFD',
    textColor: '#fff',
    summary: `
    <p class="mb-4">Our Bumble Bees program aims for children to develop the skills and confidence to take the next step into their lifelong learning journeys. We offer a dedicated program that is designed to be inclusive of each child’s stage of development and work closely with families to ensure children meet their developmental potential. </p>
    Our Bumble Bees class promotes academic as well as social and emotional intelligence as children learn to develop self-regulation and resilience in their learning. They continue to develop their knowledge and skills in language, literacy, mathematics, science, technology and art as the curriculum is delivered in a fun and creative environment that engages dynamic young minds. Our Bumble Bees also learn and practice team work, responsibility, managing conflict and learning how to be excellent global citizens.
    `,
    to: '',
    cover: '/assets/images/playhouse/bumble.jpg',
    ages: '4 - 5 years',
    banner: '/assets/images/playhouse/banner-bumble-bee.jpg',
    requiredItems: 'http://myjamboreegh.com/docs/playhouse-checklist-bumble-bees.pdf',
    levelDetails: [
      {
        type: 'age',
        summary: 'Children should be 4 years old by August 31',
      },
    ],
  },
];

const registration = {
  idCardOptions: [
    { label: 'Ghana card', value: 'Ghana card' },
    { label: 'Passport', value: 'Passport' },
    { label: 'Voters', value: 'Voters' },
    { label: 'Driver\'s License', value: 'Driver\'s License' },
    { label: 'NHIS', value: 'NHIS' },
  ],
  ethnicgroups: [
    'Black African',
    'Black Other',
    'Chinese',
    'Filipino',
    'Indian',
    'Irish Traveller',
    'Mixed ethnic group',
    'Roma',
    'White',
    'Any other ethnic group',
  ],
};

const programmeDocs = {
  calendar: 'http://myjamboreegh.com/docs/playhouse-academic-calendar.pdf',
  fees: 'http://myjamboreegh.com/docs/playhouse-day-care-fees.pdf',
};

const medicalConditions = [
  'Asthma',
  'Attention-Deficit/Hyperactivity Disorder',
  'Allergies',
  'Diabetes',
  'Hearing Impairment',
  'Visual Impairment',
  'Sickle Cell Disease',
];

const asthmaSigns = [
  'Wheezing',
  'Coughing',
  'Tightness of the chest',
  'Cold Symptoms',
  'Others',
];

const asthmaSeverity = [
  'Mild',
  'Moderate',
  'Severe',
];

const allergicReactions = [
  'Hives',
  'Watery Eyes',
  'Wheezing',
  'Coughing',
  'Tightness in the Throat',
  'Difficulty in Breathing',
];

const immunizationRequirements = [
  {
    id: 'ppd',
    label: 'BCG/PPD',
  },
  {
    id: 'dtp',
    label: 'Diphtheria, Tetanus, Pertussis (DTP, DtaP)',
  },
  {
    id: 'dt',
    label: 'Diphtheria, Tetanus, (DT) or Td (given after 7 years of age)',
  },
  {
    id: 'polio',
    label: 'Poliomyelitis (OPV or IPV)',
  },
  {
    id: 'mmr',
    label: 'MMR',
  },
  {
    id: 'measles',
    label: 'Measles',
  },
];

export default {
  playhouseDescription,
  programmes,
  registration,
  programmeDocs,
  medicalConditions,
  asthmaSigns,
  asthmaSeverity,
  allergicReactions,
  immunizationRequirements,
};
