import React from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Navigation from './Navigation';

const Header = () => {
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, left: open });
  };

  return (
    <div className="pb-4 relative" style={{ zIndex: 99, backgroundColor: '#fff' }}>
      <div className="flex text-center py-2 px-4 items-center">
        <div className="lg:hidden text-left w-1/3">
          <button type="button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </button>
        </div>
        <div className="md:w-1/3 text-center md:text-left">
          <img src="assets/images/animated-cloud.gif" style={{ maxWidth: '200px', width: '100%' }} alt="animated cloud" />
        </div>
        <div className="md:w-1/3">
          <Link to="/">
            <img style={{ maxWidth: '300px', width: '100%' }} className="block mx-auto" src="assets/images/cloud.png" alt="Jamboree logo" />
          </Link>
        </div>
        <div className="hidden md:block md:w-1/3">
          <SocialLinks />
        </div>
      </div>
      <Navigation toggleDrawer={toggleDrawer} state={state} />
    </div>
  );
};

const SocialLinks = () => (
  <div className="flex justify-right space-x-2">
    <div className="flex-grow" />
    <a href="https://web.facebook.com/Jamboree-Activity-Center-107125354137501" target="_blank" rel="noreferrer">
      <img src="assets/images/icons/facebook.jpg" className="w-8" alt="Facebook" />
    </a>
    <a href="https://instagram.com/jamboreegh_" target="_blank" rel="noreferrer">
      <img src="assets/images/icons/instagram.jpg" className="w-8" alt="Facebook" />
    </a>
  </div>
);
export default Header;
