const activities = [
  {
    id: 'asc',
    name: 'After School Club',
    cost: 900,
    accept: ['honey-bees', 'busy-bees', 'bumble-bees'],
  },
  // {
  //     id: 'dc',
  //     name: 'Discovery Club',
  //     cost: 150,
  //     accept: ['baby-bees', 'honey-bees', 'busy-bees', 'bumble-bees'],
  // },
  {
    id: 'gym',
    name: 'Gymnastics',
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
  {
    id: 'ma',
    name: 'Martial Arts',
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
  // {
  //     id: 'pg',
  //     name: 'PlayGroup',
  //     cost: 60,
  //     accept: ['baby-bees', 'honey-bees', 'busy-bees', 'bumble-bees'],
  // },
  {
    id: 'swim-baby',
    name: 'Baby Swimming',
    cost: 400,
    accept: ['baby-bees'],
  },
  {
    id: 'swim-general',
    name: 'Swimming',
    cost: 400,
    accept: ['honey-bees', 'busy-bees', 'bumble-bees'],
  },
  {
    id: 'dance',
    name: 'Dance',
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
  {
    id: 'lang',
    name: 'Languages',
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
  {
    name: 'Music',
    children: [
      {
        id: 'music-piano',
        name: 'Piano',
        cost: 400,
      },
      {
        id: 'music-guitar',
        name: 'Guitar',
        cost: 400,
      },
      {
        id: 'music-drums',
        name: 'Drums',
        cost: 400,
      },
    ],
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
  {
    id: 'robotics',
    name: 'Robotics & Coding',
    cost: 400,
    accept: ['busy-bees', 'bumble-bees'],
  },
];

export default activities;
