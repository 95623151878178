import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import qs from 'querystring';
import Popup from './Popup';
import { api } from '../utils';

const ContactForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [isError, setError] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const resetForm = () => {
    setFullName('');
    setPhoneNumber('');
    setMessage('');
    setEmail('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError(false);
    const obj = {
      full_name: fullName,
      email,
      telephone: phoneNumber,
      message,
      city: '',
    };
    // If everything is fine, dispatch to backend
    const url = api.contactUrl;
    if (processing) return;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const { data } = await axios.post(url, qs.stringify(obj), config);
      if (data.status === 'success') {
        setOpenDialog(true);
        resetForm();
        setResponseMessage('Message sent successfully. We\'ll get in touch asap.');
        setProcessing(false);
      }
    } catch (err) {
      setOpenDialog(true);
      setProcessing(false);
      setResponseMessage('Sorry, your message could not be sent due an error. Please try again.');
      setError(true);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="contact-form max-w-sm mx-auto mb-10">
        <div className="mb-4">
          <Fade triggerOnce delay={100}>
            <input
              className="w-full"
              type="text"
              required
              name="fullName"
              placeholder="Full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Fade>
        </div>
        <div className="mb-4">
          <Fade triggerOnce delay={150}>
            <input
              className="w-full"
              type="email"
              required
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Fade>
        </div>
        <div className="mb-4">
          <Fade triggerOnce delay={200}>
            <input
              className="w-full"
              type="text"
              name="phoneNumber"
              placeholder="Phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Fade>
        </div>
        <div className="mb-4">
          <Fade triggerOnce delay={250}>
            <textarea
              className="w-full"
              rows="4"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
              name="message"
              placeholder="Message"
            />
          </Fade>
        </div>
        <div>
          <Fade triggerOnce delay={300}>
            <button type="submit" className="jamb-btn">
              {processing ? 'Sending...' : 'Send message' }
            </button>
          </Fade>
        </div>
      </form>
      <Popup open={openDialog} setOpen={setOpenDialog} hideBooking>
        <div style={{ minWidth: '360px' }}>
          <h1 className="text-center" style={{ color: isError ? 'red' : 'inherit' }}>{responseMessage}</h1>
        </div>
      </Popup>
    </div>
  );
};

export default ContactForm;
