import React from 'react';
import { colors, data } from '../utils';

const { email: jamboreeEmail, website } = data;
const Footer = () => (
  <div className="relative">
    {/* <div className="max-w-4xl mx-auto mb-20" >
                <instagram-widget
                username="@jamboreegh_"
                grid="3x3" force-square="yes"
                items-limit="9"
                image-width="100%"
                image-height="100%" border-corners="5" border-spacing="2px"></instagram-widget>
            </div> */}
    <div className="py-10 px-10 text-white" style={{ background: colors.blue }}>
      <div className="md:flex">
        <div className="md:w-1/3 mb-10">
          <img className="w-full max-w-xs" src="assets/images/cloud.png" alt="Jamboree cloud" />
        </div>
        <div className="flex-grow" />
        <div className="md:w-1/3">
          <h2 className="text-2xl mb-4">Contact Us</h2>
          <div className="mb-4">
            <p>Address: No. 8 Wuogon Road, </p>
            <p>East Cantonments, Accra, Ghana</p>
          </div>
          <div className="flex space-x-2 mb-4">
            <div>Telephone</div>
            <div>
              <p>+233(0) 557 760 093</p>
              <p>+233(0) 30 279 2131</p>
            </div>
          </div>
          <div>
            <p className="flex space-x-2 mb-4">
              <div>Email:</div>
              <div><a href={`mailto:${jamboreeEmail}`}>{jamboreeEmail}</a></div>
            </p>
            <p className="flex space-x-2 mb-4">
              <div>Website:</div>
              <div><a target="_blank" href={`https://${website}`} rel="noreferrer">{website}</a></div>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
