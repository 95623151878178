import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MobileNavigation from './MobileNavigation';
import { data } from '../utils';

const { navigationList = [] } = data;
const Navigation = ({ state, toggleDrawer }) => (
  <>
    <div className="hidden lg:block lg:flex justify-center md:space-x-12 lg:space-x-12">
      {
        navigationList.map(({ name, to, color }) => (
          <div className="text-xl" style={{ color }}>
            {
              to.includes('http') ? <a href={to}>{name}</a> : <Link to={to}>{name}</Link>
            }
          </div>
        ))
      }
    </div>
    <MobileNavigation menu={navigationList} toggleDrawer={toggleDrawer} state={state} />
  </>
);

Navigation.propTypes = {
  state: PropTypes.objectOf(PropTypes.bool).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Navigation;
