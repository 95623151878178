import colors from '../colors';

const { blue, purple, orange } = colors;

const playOptions = [
  {
    name: 'Outdoor Playground',
    image: 'assets/images/play-outdoor.jpg',
    text: `
            <p class="mb-2">The Outdoor Playground features exciting and safe play equipment including an in ground
            trampoline, airplane, jungle gyms, swings, a variety of rides and free play areas for hopscotch
            and football.</p>
        `,
    bgColor: blue,
  },
  {
    name: 'Indoor Playground',
    image: 'assets/images/play-indoor.jpg',
    text: `
            <p class="mb-2">Have tons of fun exploring all our action packed play areas! Check out Jamboree Play Town
            where kids can role play and be all they want to be! The Indoor Playground offers children the
            opportunity to engage in interactive role play and to try a wide variety of activities from shopping
            at the town grocery store, to baking cupcakes at the café, building something fantastic at the
            construction site or nursing a puppy back to health at the clinic. The possibilities are endless!</p>
        `,
    bgColor: purple,
  },
  {
    name: 'Toddler Playroom and Playground',
    image: 'assets/images/play-toddler-playgroup.jpg',
    text: `
          <p class="mb-2">The Toddler Play Room and Toddler Playground feature age appropriate toys and equipment to cater to the play needs of your little ones who are 3 years and under. We have so many fun and stimulating toys and equipment including soft play and sensory toys, play mats, bricks, puzzles, ball pits, bridges with lookout points, sandbox and other play structures.</p>
      `,
    // textColor: blue,
    bgColor: orange,
  },
];

export default playOptions;
