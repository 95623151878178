import React from 'react';

const Preloader = () => (
  <div>
    <div
      id="jamb-splash-screen"
      style={{
        position: 'fixed', top: 0, left: 0, width: '100%',
      }}
    >
      <div className="center">
        <div className="logo">
          <img width="128" src="assets/images/animated-cloud.gif" alt="loading..." />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default Preloader;
