import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Bees from './svgs/Bee';
import { colors } from '../utils';

const { blue } = colors;

const ProgramsCard = ({ programs, title }) => (
  <>
    <h1 className="text-2xl text-center mb-5" style={{ color: blue }}>
      {title || 'Learn more about the programs'}
    </h1>
    <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between gap-x-4">
      {
        programs.map((
          {
            id,
            ages,
            name,
            textColor,
            cover,
            bgColor,
          },
        ) => (
          <div key={id} className="mb-4">
            <Link to={`/playhouse/${id}`}>
              <img src={cover} alt={name} />
              <div style={{ background: bgColor }} className="text-center px-4 py-2 mt-2">
                <p style={{ color: textColor }} className="text-xl my-0 flex gap-x-2 justify-center">
                  <span>{name}</span>
                  <Bees width={22} />
                </p>
                <p style={{ color: textColor }}>{ages}</p>
              </div>
            </Link>
          </div>
        ))
      }
      {/* <div>
          <img src="/assets/images/albums/christmas-camp-2020/thumb/xmas-camp-1.jpg" alt="honey" />
          <div className="text-center p-4 bg-yellow-200 mt-2">
            <p className="text-xl">Honey</p>
            <p>1 - 2 years</p>
          </div>
        </div> */}
    </div>
  </>
);

ProgramsCard.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  title: PropTypes.string,
};

ProgramsCard.defaultProps = {
  title: null,
};

export default ProgramsCard;
