const initialState = {
  firstName: '',
  middleName: '',
  medicalConditions: [],
  errors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'update':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export {
  initialState,
  reducer,
};
