import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Slide, Fade } from 'react-awesome-reveal';
import clsx from 'clsx';
import { data } from '../utils';

const { experienceOptions } = data;
const directions = ['up', 'right', 'bottom', 'left'];
const ExperienceOptions = ({ limit, hideCharacters = true }) => (
  <div className="flex flex-wrap justify-center max-w-5xl mx-auto">
    {
                experienceOptions.slice(0, limit || experienceOptions.length).map(({
                  name, color, image, link,
                }, idx) => (
                  <div className="md:w-1/3 flex-0 bg-blue">
                    <Slide direction={directions[idx % 4]} triggerOnce>
                      <ExperienceOption name={name} color={color} image={image} link={link} />
                    </Slide>
                  </div>
                ))
            }
    <div
      style={{ position: 'relative', width: '100%', height: '10px' }}
      className={clsx('hidden', { 'lg:block': !hideCharacters })}
    >
      <Fade direction="left" triggerOnce>
        <img
          style={{
            position: 'absolute', left: '-100px', top: '-400px', width: '300px',
          }}
          src="assets/images/balloon.png"
          alt="balloon"
          className="ghost"
        />
      </Fade>
      <Fade direction="right" triggerOnce>
        <img
          style={{
            position: 'absolute', right: '-100px', top: '-500px', width: '250px',
          }}
          src="assets/images/penguin.png"
          alt="penguin"
          className="ghost2"
        />
      </Fade>
    </div>
  </div>
);

const ExperienceOption = ({
  name, color, image, link,
}) => (
  <div className="text-center mb-10">
    <Link to={`experience/${link}`}>
      <img
        style={{ border: `6px solid ${color}` }}
        className="w-60 h-60 object-cover rounded-full block mx-auto mb-2"
        src={image}
        alt={name}
      />
    </Link>
    <p className="text-xl" style={{ color }}>{name}</p>
  </div>
);

ExperienceOptions.propTypes = {
  limit: PropTypes.number,
  hideCharacters: PropTypes.bool,
};
ExperienceOptions.defaultProps = {
  limit: null,
  hideCharacters: true,
};

ExperienceOption.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
};

ExperienceOption.defaultProps = {
  name: '',
  color: '',
  image: '',
  link: '',
};

export default ExperienceOptions;
