import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { colors } from '../utils';

const { orange } = colors;

const AlbumView = ({ albumList }) => (
  <div className="sm:flex sm:flex-wrap sm:justify-center">
    {
                albumList.map(({ name, cover, id }, idx) => (
                  <div className="px-4 lg:px-10 sm:w-1/3 mb-10">
                    <Slide direction="up" triggerOnce delay={idx * 100}>
                      <Link to={`/gallery/${id}`}>
                        <div
                          className="w-full h-60 bg-blue-300 mb-4"
                          style={{
                            backgroundImage: `url(${cover})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        />
                        <p style={{ color: orange }}>{name}</p>
                      </Link>
                    </Slide>
                  </div>
                ))
            }
  </div>
);

AlbumView.propTypes = {
  albumList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AlbumView;
