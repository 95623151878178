import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

const SlidingBanners = ({ slides }) => {
  const itemsPerPage = 1;
  const carouselRef = React.useRef(null);
  const totalPages = Math.ceil(slides.length / itemsPerPage);
  let resetTimeout;
  return (
    <div>
      <Carousel
        ref={carouselRef}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === totalPages) {
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(0);
            }, 5000); // same time
          }
        }}
        itemsToShow={1}
        enableAutoPlay
        autoPlaySpeed={5000}
        breakPoints={[{ width: 0, showArrows: false }]}
      >
        {
        slides.map(({ name, image }) => (
          <div key={name} className="text-center">
            <img className="mx-auto" style={{ maxWidth: '90%' }} src={image} alt={name} />
          </div>
        ))
      }
      </Carousel>
    </div>
  );
};

SlidingBanners.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default SlidingBanners;
