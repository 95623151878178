const partySteps = [
  {
    image: 'assets/images/icons/1.jpg',
    text: 'Pick your package',
  },
  {
    image: 'assets/images/icons/2.jpg',
    text: 'Choose your date/time',
  },
  {
    image: 'assets/images/icons/3.jpg',
    text: 'Choose your food option',
  },
  {
    image: 'assets/images/icons/4.jpg',
    text: 'Confirm booking with Jamboree Team',
  },
  {
    image: 'assets/images/icons/5.jpg',
    text: 'Pay your Deposit',
  },
  {
    image: 'assets/images/icons/6.jpg',
    text: 'It\'s Party Time!!!',
  },
];

const partyMessage = 'hello';

export {
  partySteps,
  partyMessage,
};
