import React from 'react';
import PlayOptions from '../components/PlayOptions';
import { colors, data } from '../utils';
import ExperienceLayout from '../layout/Experience';

const { blue } = colors;
const { play: playOptions } = data;

const Play = () => (
  <ExperienceLayout color={blue} title="Play">
    {
      playOptions.map((opt) => (
        <PlayOptions
          text={opt.text}
          name={opt.name}
          image={opt.image}
          bgColor={opt.bgColor}
        />
      ))
    }

  </ExperienceLayout>
);

export default Play;
