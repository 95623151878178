import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import useRegistrationForm from '../hooks/useRegistrationForm';
import AuthorizedInformation from './AuthorizedInformation';
import ChildInformation from './ChildInformation';
import EmergencyContactInformation from './EmergencyContactInformation';
import GuardianInformation from './GuardianInformation';
import JCheckbox from './JCheckbox';
import JTextField from './JTextField';
import RegistrationSectionCard from './RegistrationSectionCard';
import { colors } from '../utils';
import AlertModal from './AlertModal';

const { red } = colors;

const RegistrationForm = ({ level }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    state,
    dispatch,
    submitApplicationForm,
    isSubmitting,
    isSuccess,
    hasErrors,
  } = useRegistrationForm(level);

  useEffect(() => {
    if ([false, true].includes(isSuccess)) {
      setShowModal(true);
    }
  }, [isSuccess]);

  return (
    <div className="text-left">
      {
        showModal
        && (
          <AlertModal
            success={isSuccess}
            title={isSuccess ? 'Application submitted!' : 'Failed to submit application'}
            message={isSuccess ? 'We\'ll get back to you shortly' : 'Please try again later.'}
            onClick={() => setShowModal(false)}
          />
        )
      }

      <div>
        <Fade direction="up" triggerOnce>
          <RegistrationSectionCard
            heading="Child's information"
            dispatch={dispatch}
            Component={ChildInformation}
            actionLabel="Child"
            actionIdentity="firstName"
            stateData={state.children}
            levelActivities={state.activities}
          />
        </Fade>

        <Fade direction="up" triggerOnce>
          <RegistrationSectionCard
            heading="Parent/Guardian's information"
            dispatch={dispatch}
            Component={GuardianInformation}
            actionLabel="Guardian"
            actionIdentity="fullName"
            stateData={state.guardians}
          />
        </Fade>

        <Fade direction="up" triggerOnce>
          <RegistrationSectionCard
            heading="Authorized Person's information"
            dispatch={dispatch}
            Component={AuthorizedInformation}
            actionLabel="AuthorizedPerson"
            actionIdentity="fullName"
            stateData={state.authorizedPersons}
          />
        </Fade>

        <Fade direction="up" triggerOnce>
          <RegistrationSectionCard
            heading="Emergency Contact"
            dispatch={dispatch}
            Component={EmergencyContactInformation}
            actionLabel="EmergencyContact"
            actionIdentity="fullName"
            stateData={state.emergencyContacts}
          />
        </Fade>

        <Fade direction="up" triggerOnce>
          <div className="md:flex md:space-x-8">
            <JTextField
              label="Preferred start date"
              type="date"
              value={state.preferredStartDate}
              onChange={(e) => dispatch({
                type: 'update',
                payload: {
                  preferredStartDate: e.target.value,
                },
              })}
            />
            <JTextField
              onChange={(e) => dispatch({
                type: 'update',
                payload: {
                  hearAbout: e.target.value,
                },
              })}
              value={state.hearAbout}
              label="How did you hear about PlayHouse?"
              rows={3}
            />
          </div>
        </Fade>
        <Fade direction="up" triggerOnce>

          <div className="md:flex md:space-x-8">
            <JTextField
              label="Any other information"
              value={state.otherInformation}
              onChange={(e) => dispatch({
                type: 'update',
                payload: {
                  otherInformation: e.target.value,
                },
              })}
              rows={3}
            />
          </div>
        </Fade>
        <Fade direction="up" triggerOnce>
          <div className="flex">
            <div>
              <JCheckbox
                selected={state.acceptTerms}
                hasError={!state.acceptTerms}
                onChange={() => dispatch({
                  type: 'update',
                  payload: {
                    acceptTerms: !state.acceptTerms,
                  },
                })}
              >
                <span>
                  {
                    `I confirm that all the information provided by me is correct. 
                I further agree to inform Jamboree promptly in writing, of any subsequent changes to the booking. I have read and agree to the terms and conditions of this booking and the `
                  }
                </span>
                <a href="/terms" className="text-blue-500">General Terms and Conditions.</a>
              </JCheckbox>
            </div>
          </div>
        </Fade>
        <Fade direction="up" triggerOnce>
          <Button
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            onClick={() => {
              if (!isSubmitting) submitApplicationForm();
            }}
          >
            {
              isSubmitting ? 'Submitting...' : 'Submit application'
            }
          </Button>
        </Fade>
        <p className="text-xs mt-4" style={{ color: red }}>{hasErrors && 'Please check your form for errors before submitting!'}</p>
      </div>
    </div>
  );
};

RegistrationForm.propTypes = {
  level: PropTypes.string.isRequired,
};

export default RegistrationForm;
