import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import { data, colors } from '../utils';

const { red } = colors;
const { playhouse: { programmes } } = data;

const PlayHouseRegistration = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { id } = useParams();
  const programme = programmes.find((prog) => prog.id === id);
  return (
    <>
      <div style={{
        background: 'url(https://www.myjamboreegh.com/images/banners/playgroup-bg.jpg)',
      }}
      >
        <div className="text-center max-w-5xl mx-auto md:pt-10 px-4 pb-20 bg-white">
          <div
            className="text-xl mb-4"
            style={{ color: programme?.bgColor }}
          >
            {`${programme?.name} Registration Form (${programme?.ages})`}
          </div>
          <Notice show={programme?.isFull} />
          <RegistrationForm level={id} />
        </div>
      </div>
    </>
  );
};

const Notice = ({ show }) => (
  <div
    style={{ background: red }}
    className={`${show ? 'block' : 'hidden'} p-4 text-lg text-white mb-4`}
  >
    The class is currently full but you will be added to the waiting list.
  </div>
);

Notice.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default PlayHouseRegistration;
