/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import CakeIcon from '@material-ui/icons/Cake';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { Link, useParams } from 'react-router-dom';
import { data, colors } from '../utils';
import Triangle from '../components/svgs/Triangle';
import Bees from '../components/svgs/Bee';
import ProgramsCard from '../components/ProgramsCard';

const { playhouse: { programmes, programmeDocs } } = data;
const { blue, orange } = colors;

const PlayhouseDetails = () => {
  const myRef = useRef(null);
  const { id } = useParams();
  const [expandEnrolment, setExpandEnrolment] = useState(true);
  const programme = programmes.find((prog) => prog.id === id);

  useEffect(() => {
    if (id) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setExpandEnrolment(true);
    }
  }, [id]);

  const handleEnrolmentDropdown = () => {
    setExpandEnrolment(!expandEnrolment);
  };

  return (
    <div className="pb-20">
      <div className="banner-about text-center mb-10 relative max-w-6xl mx-auto" style={{ overflow: 'hidden' }}>
        <Fade triggerOnce>
          <div className="md:flex">
            <div className="md:w-2/5 items-center justify-center p-6 md:flex" style={{ background: programme?.bgColor, position: 'relative' }}>
              <div
                className="xs:hidden md:block"
                style={{
                  position: 'absolute', zIndex: 1, left: '100%',
                }}
              >
                <Triangle fill={programme?.bgColor} />
              </div>
              <div
                className="xs:hidden md:block"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: '0',
                }}
              >
                <Fade triggerOnce delay={900} direction="up"><img className="hidden md:block" src="/assets/images/playhouse/color-shapes.png" alt="colors" style={{ width: '140%' }} /></Fade>
              </div>
              <div className="text-white text-4xl md:text-5xl text-left" style={{ position: 'relative', zIndex: 2 }}>
                <Slide delay={500} direction="up" triggerOnce>
                  <h1 className="mb-2 flex gap-x-2">
                    <span>{programme?.name}</span>
                    <Bees width={48} />
                  </h1>
                </Slide>
                <div className="text-lg mt-2 md:mt-8">
                  <Fade delay={800} triggerOnce direction="right">
                    <p className="text-xl mb-4">
                      {`Ages: ${programme?.ages}`}
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
            <img className="mx-auto md:w-3/5" src={programme?.banner} alt={programme?.name} />
          </div>
        </Fade>
      </div>
      <div className="max-w-5xl mx-auto px-4">
        <div className="mb-12 md:flex gap-x-12">
          <div className="md:w-1/2 p-10 mb-8 text-white text-left" style={{ background: programme?.bgColor }}>
            <h2 className="text-lg mb-4 flex gap-x-2">
              <span>{programme?.name}</span>
              <Bees width={24} />
            </h2>
            <div dangerouslySetInnerHTML={{ __html: programme?.summary }} className="text-sm mb-6" />
            <Fade delay={1000} triggerOnce direction="up">
              {
                programme?.levelDetails && programme.levelDetails.map(
                  ({ type, summary }) => <LevelDetails type={type} summary={summary} />,
                )
              }
            </Fade>
          </div>
          <div className="md:w-1/2 px-10 text-left" ref={myRef} style={{ color: programme?.bgColor }}>
            <Accordion
              expanded={expandEnrolment}
              onChange={handleEnrolmentDropdown}
              style={{
                background: orange,
                boxShadow: 'none',
                color: '#FFF',
              }}
            >
              <AccordionSummary>
                Enrolment Forms
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: '100%' }}>
                  <NavItem
                    name="Registration Form"
                    link={`/playhouse/${id}/register`}
                    color={blue}
                  />
                  <NavItem
                    name="Medical Form"
                    link="/playhouse/medical-form"
                    color={blue}
                  />
                  <NavItem
                    name="Parent Consent Form"
                    link="/playhouse/parent-consent-form"
                    color={blue}
                  />
                  {/* <NavItem
                    name="Fees"
                    link={programmeDocs?.fees}
                    color={blue}
                    isExternal
                  /> */}
                  <NavItem
                    name="Calendar"
                    link={programmeDocs?.calendar}
                    color={blue}
                    isExternal
                  />
                  <NavItem
                    name="Items to pack"
                    link={programme?.requiredItems}
                    color={blue}
                    isExternal
                  />
                  <NavItem
                    name="Terms &amp; Conditions"
                    link="/terms"
                    color={blue}
                    isExternal
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <ProgramsCard programs={programmes} />
      </div>
    </div>
  );
};

const LevelDetails = ({ type, summary }) => {
  let icon = null;
  switch (type) {
    case 'age':
      icon = <CakeIcon />;
      break;
    case 'training':
      icon = <EmojiPeopleIcon />;
      break;
    case 'enrolment':
      icon = <GroupIcon />;
      break;
    case 'teacher':
      icon = <LocalLibraryIcon />;
      break;
    default:
      icon = <InfoIcon />;
  }
  return (
    <div className="flex mb-4">
      <div className="flex-0 flex-shrink-0" style={{ width: '32px', height: '32px', marginRight: '5px' }}>
        {icon}
      </div>
      <div>
        {summary}
      </div>
    </div>
  );
};

LevelDetails.propTypes = {
  type: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

const NavItem = ({
  name,
  link,
  isExternal,
  color,
}) => {
  const style = {
    background: color,
    color: 'white',
    padding: '5px 10px',
    marginBottom: '10px',
    borderRadius: '6px',
  };

  const textWrapper = <div style={style}>{name}</div>;
  return (
    <div>
      {
        isExternal
          ? <a href={link} target="_target">{textWrapper}</a>
          : <Link to={link}>{textWrapper}</Link>
      }
    </div>
  );
};

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
};

NavItem.defaultProps = {
  isExternal: false,
};

export default PlayhouseDetails;
