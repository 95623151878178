const hairhub = {
  text: `
    <p class="mb-4">Look, feel and be absolutely fabulous at our in-house grooming centre, Hair Hub Kids!</p>
    <p class="mb-4">Hair Hub Kids caters to all hair types and is a one stop shop for your hair braiding, styling, barbering, and mani-pedi needs. As natural hair specialists, we make sure that our clients not only look great, but their hair is properly cared for too.</p> 
    <p class="mb-4">HairHub Kids is the perfect place for a pampering date or a glamorous birthday party! </p>
    <p class="mb-4">…and don’t forget, we cater to mum and dad too!</p>
    `,
  image: 'assets/images/hairhub.jpg',
};

export default hairhub;
