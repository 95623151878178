import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import { colors } from '../utils';

const { green, red } = colors;

const AlertModal = ({
  success,
  title,
  message,
  onClick,
}) => {
  const color = success ? green : red;
  return (
    <div
      style={{
        background: 'rgba(0,0,0,0.5)',
        position: 'fixed',
        width: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: '100vh',
        top: '0',
        left: 0,
        zIndex: '9999999999',
      }}
    >
      <div style={{ height: '100vh' }} className="">
        <Fade>
          <div className="relative w-full p-10 flex text-center items-center justify-center" style={{ height: '100vh' }}>
            <div
              className="bg-white w-full max-w-2xl p-3 newsletter-modal"
              style={{
                backgroundImage: 'url(assets/images/characters/subscribe-char.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '180px',
              }}
            >
              <div className="p-4 md:p-10 relative" style={{ border: '1px solid #ddd' }}>
                <div className="flex flex-col max-w-sm md:max-w-xs mx-auto">
                  <h2 className="text-2xl mb-2" style={{ color }}>{title}</h2>
                  <p className="mb-8">{message}</p>
                  <button onClick={onClick} type="button">OK</button>
                </div>
              </div>
            </div>
          </div>

        </Fade>
      </div>
    </div>
  );
};

AlertModal.propTypes = {
  success: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AlertModal;
