/* eslint-disable react/no-danger */
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { colors, data } from '../utils';
import Triangle from '../components/svgs/Triangle';
import ProgramsCard from '../components/ProgramsCard';

const { purple, blue } = colors;

const { playhouse: { playhouseDescription, programmes } } = data;

const PlayhouseHome = () => (
  <div className="pb-20">
    <div className="banner-about text-center mb-10 relative max-w-6xl mx-auto" style={{ overflow: 'hidden' }}>
      <Fade triggerOnce>
        <div className="md:flex">
          <div className="md:w-2/5 items-center justify-center hidden md:flex" style={{ background: '#10DBB6', position: 'relative' }}>
            <div
              className="xs:hidden md:block"
              style={{
                position: 'absolute', zIndex: 1, left: '100%',
              }}
            >
              <Triangle />
            </div>
            <div
              className="xs:hidden md:block"
              style={{
                position: 'absolute',
                zIndex: 1,
                left: '0',
              }}
            >
              <Fade triggerOnce delay={900} direction="up"><img src="/assets/images/playhouse/color-shapes.png" alt="colors" style={{ width: '140%' }} /></Fade>
            </div>
            <div className="text-white text-2xl md:text-4xl text-left">
              <Slide delay={500} direction="up" triggerOnce><h1 className="mb-2">Jamboree</h1></Slide>
              <Slide delay={600} direction="up" triggerOnce>
                <h1 className="flex items-center gap-x-2">
                  <img alt="Playhouse" src="/assets/images/playhouse/playhouse-logo.png" style={{ width: '48px' }} />
                  <span>PlayHouse</span>
                </h1>
              </Slide>
              <div className="text-lg mt-8">
                <Fade delay={800} triggerOnce direction="right"><p>Register your little ones from ages</p></Fade>
                <Fade delay={900} triggerOnce direction="right"><p>1 to 5 years.</p></Fade>
              </div>
            </div>
          </div>
          <img className="mx-auto md:w-3/5" src="/assets/images/playhouse/playHouse-home.jpg" alt="PlayHouse" />
        </div>
      </Fade>
    </div>
    <div className="max-w-5xl mx-auto px-4">
      <Fade triggerOnce delay={500} direction="up">
        <h1 className="text-3xl mb-6 flex items-center gap-x-4 justify-center" style={{ color: purple }}>
          <img alt="Playhouse" src="/assets/images/playhouse/playhouse-logo.png" style={{ width: '48px' }} />
          <span>PlayHouse Day Care Centre</span>
        </h1>
        <div className="mb-16" dangerouslySetInnerHTML={{ __html: playhouseDescription }} style={{ color: blue }} />
      </Fade>
      <ProgramsCard programs={programmes} />
    </div>
  </div>
);

export default PlayhouseHome;
