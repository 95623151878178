import React from 'react';
import PropTypes from 'prop-types';
import { data } from '../utils';

const { slideOutOptions } = data;

const slideOutStyle = {
  position: 'fixed',
  top: '400px',
  right: '156px',
  width: '100px',
  zIndex: 99,
};

const Slideout = ({ setShowNewsletter }) => {
  const performAction = (action) => {
    switch (action) {
      case 'booking':
        window.open('https://www.myjamboreegh.com/booking', '_blank').focus();
        break;
      case 'subscribe':
        setShowNewsletter(true);
        break;
      case 'summer-camp':
        window.open('https://www.myjamboreegh.com/docs/summer-camp-timetable.pdf', '_blank').focus();
        break;
      case 'christmas-camp':
        window.open('https://www.myjamboreegh.com/docs/christmas-camp-timetable-2022.pdf', '_blank').focus();
        break;
      case 'holiday-camp':
        window.open('https://www.myjamboreegh.com/docs/summer-camp-timetable.pdf', '_blank').focus();
        break;
      default:
        window.open('https://www.myjamboreegh.com/docs/timetable.jpg', '_blank').focus();
        break;
    }
  };

  return (
    <div style={slideOutStyle} className="">
      <div className="transform rotate-90" style={{ width: '500px', position: 'fixed' }}>
        <div className="flex space-x-4">
          {
            slideOutOptions.map(({
              name, id, color, bgColor,
            }) => (
              <CircularButton
                key={id}
                name={name}
                id={id}
                color={color}
                bgColor={bgColor}
                performAction={performAction}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

const CircularButton = ({
  name = '', id = '', color = '#fff', bgColor, performAction,
}) => (
  <div
    style={{
      background: bgColor,
      cursor: 'pointer',
    }}
    role="button"
    tabIndex={0}
    className="slideOutBtn relative w-20 h-20 rounded-full flex text-center items-center bg-blue-400 justify-center"
    onClick={() => performAction(id)}
    onKeyDown={() => { }}
  >
    <p className="text-xs" style={{ position: 'relative', bottom: '-10px', color }}>{name}</p>
  </div>
);

Slideout.propTypes = {
  setShowNewsletter: PropTypes.func.isRequired,
};

CircularButton.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  performAction: PropTypes.func.isRequired,
};

CircularButton.defaultProps = {
  color: '#fff',
  bgColor: '#000',
};

export default Slideout;
