import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxOutlined, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';

const JRadio = ({
  label,
  options,
  selected,
  hasError,
  onChange,
  errorMessage,
  isInline,
  required,
}) => {
  const color = hasError ? 'red' : '#17bffd';
  return (
    <div className="mb-4">
      {
        !isInline
        && (
          <p className="mb-1 text-xs">
            {label}
            {
              required ? <span style={{ color: 'red' }}> *</span> : ''
            }
          </p>
        )
      }
      <div className={isInline ? 'flex gap-x-4' : ''}>
        {options.map((option) => (
          // eslint-disable-next-line
          <div
            key={option.value}
            style={{
              cursor: 'pointer',
              // color: option.value === selected ? color : 'inherit',
            }}
            onClick={() => onChange(option.value)}
          >
            {
              option.value === selected ? <CheckBoxOutlined style={{ color }} />
                : <CheckBoxOutlineBlankOutlined style={{ color }} />
            }
            <span style={{ paddingLeft: '5px' }}>{option.label}</span>
          </div>
        ))}
      </div>
      <div style={{ color, fontSize: '12px', marginTop: '5px' }}>
        {hasError ? errorMessage : ''}
      </div>
    </div>
  );
};

JRadio.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isInline: PropTypes.bool,
  required: PropTypes.bool,
};

JRadio.defaultProps = {
  errorMessage: '',
  isInline: false,
  required: false,
};

export default JRadio;
