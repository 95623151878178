import React from 'react';
import PropTypes from 'prop-types';

const JSelect = ({
  label,
  value,
  onChange,
  required,
  hasError,
  errorMessage,
  options,
}) => {
  const color = hasError ? 'red' : '#17bffd';
  const inputStyle = {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px solid ${color}`,
    padding: '5px 10px',
    borderRadius: '5px',
    // color,
  };
  return (
    <div className="mb-4 flex-1">
      <p className="mb-1 text-xs">
        {label}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </p>
      <select style={inputStyle} onChange={onChange}>
        <option value="" selected="selected">Please select</option>
        {
          options.map((option) => (
            <option
              value={option.value}
              selected={value === option.value}
            >
              {option.label}
            </option>
          ))
        }
      </select>
      <div style={{ color, fontSize: '12px', marginTop: '5px' }}>
        {hasError ? errorMessage : ''}
      </div>
    </div>
  );
};

JSelect.propTypes = {
  label: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  options: PropTypes.bool.isRequired,
};

JSelect.defaultProps = {
  errorMessage: '',
};

export default JSelect;
