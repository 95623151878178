import React from 'react';
import MainLayout from '../layout/Main';
import { data } from '../utils';
import AlbumView from '../components/AlbumView';

const { albumList } = data;

const Gallery = () => (
  <MainLayout title="Gallery">
    <div className="text-center pt-10 pb-20 max-w-6xl mx-auto">
      <AlbumView albumList={albumList} />
    </div>
  </MainLayout>
);

export default Gallery;
