import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import MainLayout from '../layout/Main';
import PlayhouseDetails from './PlayhouseDetails';
import PlayhouseHome from './PlayhouseHome';
import PlayHouseMedicals from './PlayhouseMedicals';
import PlayHouseParentConsent from './PlayhouseParentConsent';
import PlayHouseRegistration from './PlayhouseRegistration';

const Playhouse = () => {
  const { path } = useRouteMatch();
  return (
    <MainLayout title="PlayHouse">
      <Switch>
        <Route exact path={`${path}/:id/register`}>
          <PlayHouseRegistration />
        </Route>
        <Route exact path={`${path}/parent-consent-form`}>
          <PlayHouseParentConsent />
        </Route>
        <Route exact path={`${path}/medical-form`}>
          <PlayHouseMedicals />
        </Route>
        <Route exact path={`${path}/:id`}>
          <div className="text-center max-w-5xl mx-auto md:pt-10 pb-20">
            <PlayhouseDetails />
          </div>
        </Route>
        <Route exact path={`${path}`}>
          <div className="text-center max-w-5xl mx-auto md:pt-10 pb-20">
            <PlayhouseHome />
          </div>
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default Playhouse;
