import React from 'react';
import PropTypes from 'prop-types';
import JRadio from './JRadio';

const ConsentCard = ({
  text,
  onChange,
  value,
  hasError,
}) => (
  <div className="mb-4 text-left md:flex justify-between">
    <p className="text-sm md:w-3/4">
      {text}
    </p>
    <JRadio
      onChange={onChange}
      selected={value}
      isInline
      hasError={hasError}
      options={[
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ]}
    />
  </div>
);

ConsentCard.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

ConsentCard.defaultProps = {
  hasError: false,
};

export default ConsentCard;
