import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import MainLayout from '../layout/Main';
import ExperienceOptions from '../components/ExperienceOptions';
import Play from './Play';
import Learn from './Learn';
import Grow from './Grow';
import Cafe from './Cafe';
import Hairhub from './Hairhub';

const Experience = () => {
  const { path } = useRouteMatch();
  return (
    <MainLayout title="Experience">
      <Switch>
        <div className="text-center max-w-5xl mx-auto md:pt-10 pb-20">
          <Route exact path={`${path}/grow`}>
            <Grow />
          </Route>
          <Route exact path={`${path}`}>
            <ExperienceOptions hideCharacters={false} />
          </Route>
          <Route exact path={`${path}/play`}>
            <Play />
          </Route>
          <Route exact path={`${path}/learn`}>
            <Learn />
          </Route>
          <Route exact path={`${path}/cafe`}>
            <Cafe />
          </Route>
          <Route exact path={`${path}/hairhub-kids`}>
            <Hairhub />
          </Route>
        </div>
      </Switch>
    </MainLayout>
  );
};

export default Experience;
