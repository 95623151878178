const christmasCamp2020 = [];
const easterCamp2021 = [];
const discoveryClub2021 = [];

for (let i = 1; i < 19; i += 1) {
  christmasCamp2020.push({
    name: 'Christmas Camp 2020',
    thumbnailUrl: `assets/images/albums/christmas-camp-2020/thumb/xmas-camp-${i}.jpg`,
    imageUrl: `assets/images/albums/christmas-camp-2020/large/xmas-camp-${i}.jpg`,
  });
}

for (let i = 1; i < 26; i += 1) {
  discoveryClub2021.push({
    name: 'Discovery Club 2021',
    thumbnailUrl: `assets/images/albums/discovery-club-2021/thumb/discovery-club-${i}.jpg`,
    imageUrl: `assets/images/albums/discovery-club-2021/large/discovery-club-${i}.jpg`,
  });
}

for (let i = 1; i < 25; i += 1) {
  easterCamp2021.push({
    name: 'Easter Camp 2021',
    thumbnailUrl: `assets/images/albums/easter-camp-2021/thumb/easter-camp-${i}.jpg`,
    imageUrl: `assets/images/albums/easter-camp-2021/large/easter-camp-${i}.jpg`,
  });
}

const albumList = [
  {
    name: 'Discovery Club 2021',
    id: 'discovery-club-2021',
    cover: 'assets/images/albums/discovery-club-2021/thumb/discovery-club-2.jpg',
    photos: [
      ...discoveryClub2021,
    ],
  },
  {
    name: 'Easter Camp 2021',
    id: 'easter-camp-2021',
    cover: 'assets/images/albums/easter-camp-2021/thumb/easter-camp-2.jpg',
    photos: [
      ...easterCamp2021,
    ],
  },
  {
    name: 'Christmas Camp 2020',
    id: 'christmas-camp-2020',
    cover: 'assets/images/albums/christmas-camp-2020/thumb/xmas-camp-1.jpg',
    photos: [
      ...christmasCamp2020,
    ],
  },
];

export default albumList;
