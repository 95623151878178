/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import { Slide, Fade } from 'react-awesome-reveal';
import MainLayout from '../layout/Main';
import SlidingBanners from '../components/SlidingBanners';
import ExperienceOptions from '../components/ExperienceOptions';
import Carousel from '../components/Carousel';
import { colors, data } from '../utils';

const {
  flyers, peeks, about: { aboutSummary }, slidingBanners,
} = data;

const Home = () => (
  <MainLayout title="Home">
    <div className="text-center pb-20">
      <Fade direction="up" triggerOnce>
        <SlidingBanners slides={slidingBanners} />
      </Fade>

      <div className="py-10 max-w-5xl mx-auto mb-10 px-4">
        <Slide triggerOnce>
          <h2 className="text-3xl mb-8" style={{ color: colors.purple }}>Discover the Amazing You</h2>
        </Slide>
        <Slide triggerOnce direction="up">
          <div className="mb-8" style={{ color: colors.blue }} dangerouslySetInnerHTML={{ __html: aboutSummary }} />
        </Slide>
        <Fade triggerOnce direction="left">
          <Link className="jamb-button-outlined" to="/about-us">Read more</Link>
        </Fade>
      </div>

      <div className="mb-20">
        <Slide triggerOnce direction="up">
          <ExperienceOptions limit={3} />
        </Slide>
      </div>

      <div className="mb-20">
        <Slide triggerOnce direction="up">
          <Carousel
            dots
            title="What's on at Jamboree"
            titleColor={colors.purple}
            items={flyers}
            spaceItems
            hasPopup
            autoPlay
          />
        </Slide>
      </div>

      <div id="peek-carousel">
        <Slide triggerOnce direction="up">
          <Carousel
            title="Peek inside Jamboree's Play space"
            dots
            numItems={1}
            titleColor={colors.purple}
            items={peeks}
            autoPlay={false}
          />
        </Slide>
      </div>
    </div>
  </MainLayout>
);

export default Home;
