import {
  useCallback,
  useReducer,
  useState,
  useEffect,
} from 'react';
import axios from 'axios';
import { reducer, initialState } from '../reducers/registrationReducer';
import useRegistrationFormValidator from './useRegistrationFormValidator';
import { api, data } from '../utils';

const { activities } = data;

const useRegistrationForm = (level) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { validateForm } = useRegistrationFormValidator({ state, dispatch });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [hasErrors, setHasErrors] = useState(null);

  useEffect(() => {
    if (level) {
      const levelActivities = activities.map((activity) => {
        if (activity.accept.includes(level)) {
          return activity;
        }
        return null;
      }).filter((lactivity) => lactivity !== null);
      dispatch({
        type: 'update',
        payload: {
          activities: levelActivities,
          level,
        },
      });
    }
  }, [level]);

  const submitApplicationForm = useCallback(() => {
    const url = api.playhouseRegistrationUrl;
    const validChildren = validateForm('children');
    const validGuardian = validateForm('guardians');
    const validAuthorizedPersons = validateForm('authorizedPersons');
    const validEmergencyContacts = validateForm('emergencyContacts');
    if (
      validChildren
      && validGuardian
      && validAuthorizedPersons
      && validEmergencyContacts
      && state.acceptTerms
    ) {
      setHasErrors(false);
      setIsSubmitting(true);
      setIsSuccess(null);
      axios.post(url, state)
        .then(() => {
          setIsSuccess(true);
          dispatch({ type: 'reset' });
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setHasErrors(true);
    }
  }, [state]);

  return {
    state,
    dispatch,
    submitApplicationForm,
    isSubmitting,
    isSuccess,
    hasErrors,
  };
};

export default useRegistrationForm;
